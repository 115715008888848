<template>
    <div class="login-modal-container" v-if="is_login_modal">

        <div class="login-modal" >
            <div class="login-modal-header">
                <div class="login-modal-header-title">Авторизация в профиле</div>
                <div class="login-modal-header-close" @click="close"><i class="las la-times"></i></div>
            </div>
            <div class="login-modal-body">

                <Alert type="danger" v-if="is_error" :message="error_text"/>

                <form class="pure-form pure-form-aligned">
                    <fieldset>
                        <div class="pure-u-1 pure-u-md-1">
                            <label for="aligned-login">Логин</label>
                            <select id="aligned-login" class="pure-input-1" v-model="profileId">
                                <option  v-for="(item, index) in profiles" :key="'popt'+index+(new Date().getTime())" :value="item.id">{{ item.name }} ({{item.login}}) {{item.profileId}}</option>
                            </select>
                        </div>
                        <div class="pure-u-1 pure-u-md-1">
                            <label for="aligned-password">Пароль</label>
                            <input type="password"  class="pure-input-1" id="aligned-password" placeholder=""  @input="event => password = event.target.value"/>
                        </div>
                        <div class="pure-controls login-modal-body-info">
                            <!--label for="aligned-cb" class="pure-checkbox">
                                <input type="checkbox" id="aligned-cb" /> Не выходить из профиля при закрытии окна (менее безопасно)
                            </label-->
                            <button type="submit" class="pure-button pure-button-primary" @click="login">Войти</button>
                        </div>
                    </fieldset>
                </form>

                <div class="login-modal-body-links">

                    <router-link to="/profiles" @click="close"> Все профили</router-link> |
                    <router-link to="/create-profile" @click="close"> Создать профиль</router-link>

                </div>

            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'

import IDB from '@/utils/idb'

import Alert from '@/components/blocks/Alert.vue'

export default {
    name: 'AlertModal',
    props: {
    },
    components: {
        Alert
    },
    computed: {
        ...mapState({
            "is_login_modal":  state => state.app.is_login_modal
        }),
    },
    methods: {
        close(){

            this.$store.dispatch('app/setLoginModal', { 
                show:  false
            });
        },
        getProfiles() {

            IDB.profiles().then((profiles) => {
                this.profiles = profiles;

                // профиль по умалчанию из списка
                if(typeof  profiles[0] !== "undefined") {
                    this.profileId = profiles[0].id;
                }

            }).catch(() => {

                this.is_error = true;
                this.error_text = "Ошибка чтения из локальной базы данных!";
                           
            });

        },
        getProfile(profileId, callback) {

            // ------------- Чтения данных
            IDB.getProfile(profileId).then((profile) => {

                callback(profile);

            }).catch(() => {

                this.is_error = true;
                this.error_text = "Ошибка чтения из локальной базы данных!";

            });
        },
        login(event) {
                event.preventDefault();


                this.getProfile(this.profileId, (profileData) => {

                    try {

                        const decryptedText = this.$CryptoJS.AES.decrypt(profileData.data, this.password).toString(this.$CryptoJS.enc.Utf8);

                        console.log(typeof decryptedText);

                        if(decryptedText.trim() == "")  {
                            
                            this.is_error = true;
                            this.error_text = "Мастер-пароль не подходит!";

                        } else  {

                            let data = JSON.parse(decryptedText);
                            let profile = {
                                id: profileData.id,
                                name: profileData.name,
                                login: profileData.login,
                                data
                            };


                            this.$store.dispatch('app/setProfile', { 
                                profile,
                                password: this.password,
                            });

                            this.close();

                        } // end if

                    } catch (err) {

                        this.is_error = true;
                        this.error_text = "Ошибка чтения профиля!";

                    }

                });     
        
        }
    },
    data: () => ({
        profiles: [],
        profileId: 0,
        password: "",

        is_error: false,
        error_text: ""

    }),

    watch: {
        '$route'(){
            
            this.getProfiles();
        }
    },

    mounted() {

        this.getProfiles();
        
    }
}


</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
/*** Alerts ***/

.login-modal {
    padding: 0;
    color: inherit;

    margin:0 auto;
    width: 90%;
    max-width:500px;

    border: 1px solid  lightgrey;
    border-radius: 15px;
    background-color: #fafafa;



    &-container {
        position: fixed;
        top: 50%;
        width: 100%;
        padding:0;
        margin-top: -170px;
    }

    &-header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        &-title {
            font-size:16px;
            font-weight: bold;
            text-align: left;
            padding: 20px;
        }

        &-close {
            font-size:22px;
            text-align: right;
            padding: 20px;
        }

    }

    &-body {
        padding: 0 20px;


        input[type=password] {
            width: 100% !important;
        }

        &-info {
            font-size: 12px;
        }

        &-links {
            padding:25px 0;
            text-align: center;

            a {
                color:black;
                font-size:14px;
            }
        }
    }
}

</style>