<template>
    <div class="cloud">

     <Loader :loading="loading" />

       <h1><i class="las la-cloud"></i> Блокчейн Облоко ({{ countCloud }})</h1>   


        <Alert v-if="is_error" type="danger" :message="error_text"/>

        <Transaction :id="transactionId" :title="transactionIdTitle"/>

        <div class="cloud__buttons">
            <div class="cloud__buttons-item">
                <router-link to="/cloud-add-file" class="pure-button pure-button-primary"><i class="las la-cloud-upload-alt"></i>&nbsp; Добавить файл</router-link>
            </div>
            <div class="cloud__buttons-item">
                <router-link to="/browser" class="pure-button pure-button-primary"><i class="las la-cloud-download-alt"></i>&nbsp; Получить файл</router-link>
            </div>
        </div>



        <div class="cloud__list" id="content-scroll">
            <div class="cloud__list-item"  v-for="(item, index) in listCloud" :key="'pr'+item.fileId+index+(new Date().getTime())"> 
                    <div class="cloud__list-item-header" v-if="item.title">
                        <div class="cloud__list-item-title"><router-link :to="'/cloud-file/'+item.fileId">{{ item.title }}</router-link></div>
                    </div>
                    <div class="cloud__list-item-text-container">
                    <div class="cloud__list-item-text">

                        <router-link :to="'/cloud-file/'+item.fileId" v-if="!item.title">Открыть<br /><br /></router-link>

                        <div v-if="item.no_encrypt" class="cloud__list-inp-noencrypt">Файл доступен без пароля</div>

                        <div v-if="item.name !== ''">
                            Имя: <b>{{item.name}}</b> <br />
                            Размер:  <b>{{formatBytes(item.size)}}</b> <br />
                            Тип:  <b>{{item.type}}</b>
                        </div>

                        <div class="cloud__list-inp-image" v-if="file_is_image(item.type)" @click="fileImageMin(item.fileId)">
                            <img :src="item.content"  
                                 :class="item.is_image_min?'cloud__list-inp-image-min':'cloud__list-inp-image-max'"
                            />
                        </div>

                        <div class="cloud__list-inp-link" v-if="item.name !== ''">
                            <i class="las la-file-download"></i> <a :download="item.name" :href="item.content">Скачать</a>
                        </div>

        
                    </div>
                   </div>
                    <div class="cloud__list-item-create">{{ getCreateDate(item.ts, item.ts_update) }}</div>
                    <div class="cloud__list-item-footer">
                        <div class="cloud__list-item-date">{{ getDate(item.ts, item.ts_update) }}</div>
                        <div class="cloud__list-item-title">
                            <router-link class="pure-button" :to="'/cloud-update-file/'+item.fileId">
                                <i class="las la-pencil-alt"></i>
                            </router-link>
                            &nbsp;
                            <a href="" class="pure-button" @click="e => removeFile(e, item.fileId)">
                                <i class="las la-times"></i>
                            </a>
                        </div>
                    </div>
            </div>
        </div>
  
   </div>
 </template>
 
 <script>
 import router from '@/router/router'

 import DT from '@/utils/dt'
 import Utils from '@/utils/utils'

 import { mapState, mapGetters } from 'vuex'

import Loader from '@/components/blocks/Loader.vue'
import Alert from '@/components/blocks/Alert.vue'
import Transaction from '@/components/blocks/Transaction.vue'


import CloudABI from '@/abi/cloud.json'
 
 export default {
     name: 'Cloud',
     components: {
            Loader,
            Alert,
            Transaction
    },
     props: {
     },
     computed: {
            ...mapState({
                "web3": state => state.app.web3,
                "currentAddress": state => state.app.currentAddress,
                "currentProfile": state => state.app.currentProfile,
                "currentPass": state => state.app.currentPass,
            }),
    
            ...mapGetters({
            }),

            listCloud() {
                return this.cloud.filter(item => Number(item.fileId) != 0);
            },


     
     },
     methods: {

        file_is_image(file_type) {
            return file_type == "image/jpeg" || file_type == "image/png" || file_type == "image/gif";
        },

        fileImageMin(id) {

            let cloud = this.cloud;
            cloud = [...cloud];

            console.log('id: '+id);

            for(let i in cloud) {
                if(cloud[i].fileId == id) {
                    cloud[i].is_image_min = !cloud[i].is_image_min;
                    break;
                }
            }

            this.cloud = [...cloud];
        },

        formatBytes(size) {
            return Utils.formatBytes(size);
        },

        getKeyPassword(keyId) {
            let profile = this.currentProfile;
            let keys = profile.data.keys;

            return keys[keyId].password;
        },

        is_limit(str) {

            // удаляем теги
            let res = Utils.cutTags(str);

            return res.length > this.limit;

        },

        getCreateDate(ts, ts_update) {
            if(ts == ts_update) {
                return "";
            }

            return DT.getFormatDate(ts+"");
        },

        getDate(ts, ts_update) {
            if(ts == ts_update) {
                return DT.getFormatDate(ts+"");
            }

            return "обн. "+DT.getFormatDate(ts_update+"");
        },

        getCountCloud(callback) {

            this.token = new this.web3.eth.Contract(CloudABI, this.cloudAddress, { from: this.currentAddress });

            this.listLoading = true;

            this.token.methods.getCountCloud().call().then((count) => {
                callback(count);

            }).catch((err) => {
                if (err) {

                    this.$store.dispatch('app/setAlert', { 
                        is_alert:  true,
                        type:  "danger",
                        message: "Ошибка получения данных!",
                    });

                    return
                }
                            
            }).finally(() => {
                this.listLoading = false;
            });



        },

        getFormatArr(res) {
            let newCloud = res.filter(item => item.text !== ":d;").map((item) => { 

                let title = "";
                let name = "";
                let size = "";
                let type = "";
                let content = "";

                let no_encrypt = false;

                try {

                    // если текст не шифрованный парсим Json
                    if(item.text[0] == "{") {

                        let obj = JSON.parse(item.text);

                        title = obj.title;
                        name  = obj.name;
                        size  = obj.size;
                        type  = obj.type;
                        content  = obj.content;

                        no_encrypt = true;

                    // если текст шифрованный, дешифруем и парсим Json
                    } else {
                        

                        let codes = item.text.split(';');
                        let subcodes = codes[0].split(':');

                        if(subcodes[0] == 's' && subcodes[1] == 'aes') {
                            const decryptedText = this.$CryptoJS.AES.decrypt(codes[1], this.getKeyPassword(subcodes[2])).toString(this.$CryptoJS.enc.Utf8);

                            console.log(decryptedText);
                        

                            let obj = JSON.parse(decryptedText);

                            title = obj.title;
                            name  = obj.name;
                            size  = obj.size;
                            type  = obj.type;
                            content  = obj.content;
                        }

                    }

                } catch (error) {

                    title = "Не удалось расшифровать!";
                }

                return { fileId: item.fileId, title, name, size, type, content, ts: item.ts, ts_update: item.ts_update, is_image_min: true, no_encrypt };

            });

            return newCloud;
        },

        getCloudDesc(fileId, count) {

            this.token = new this.web3.eth.Contract(CloudABI, this.cloudAddress, { from: this.currentAddress });


            this.loading = true;

            this.token.methods.getCloudDesc(fileId, count).call().then((res) => {
                
                console.log(res);
            
                let newCloud = this.getFormatArr(res);

                this.newCloud = newCloud;

                // удаляем дубликаты
                let resCloud = [];
                for(let i in this.cloud) {

                    let is_dub = false;
                    for(let n in this.newCloud) {
                        if(this.cloud[i].fileId == this.newCloud[n].fileId) {
                            is_dub =  true;
                        }
                    }

                    if(!is_dub) resCloud.push(this.cloud[i]);
                }

                // добавляеем загруженные записи в список
                resCloud.push(...newCloud);

                this.cloud = resCloud;

            }).catch(() => {

                this.$store.dispatch('app/setAlert', { 
                    is_alert:  true,
                    type:  "danger",
                    message: "Ошибка получения данных!",
                });
                
            }).finally(() => {

                this.loading = false;

                console.log("finally");
            });
            

        },

        getCloud(fileId, count) {

            this.token = new this.web3.eth.Contract(CloudABI, this.cloudAddress, { from: this.currentAddress });

            this.token.methods.getCloud(fileId, count).call((err, res) => {
                if (err) {
                    
                    this.$store.dispatch('app/setAlert', { 
                        is_alert:  true,
                        type:  "danger",
                        message: "Ошибка получения данных!"
                    });

                    return
                }

                console.log(res);


                let newCloud = this.getFormatArr(res);

                this.newCloud = newCloud;

                // удаляем дубликаты
                let resCloud = [];
                for(let i in this.cloud) {

                    let is_dub = false;
                    for(let n in this.newCloud) {
                        if(this.cloud[i].fileId == this.newCloud[n].fileId) {
                            is_dub =  true;
                        }
                    }

                    if(!is_dub) resCloud.push(this.cloud[i]);
                }

                // добавляеем загруженные записи в список
                resCloud.push(...newCloud);

                this.cloud = resCloud;


            })

        },

        removeFile(event, fileId) {
            event.preventDefault();

            this.loading = true;

            this.contractToken = new this.web3.eth.Contract(CloudABI, this.cloudAddress, { from: this.currentAddress });

            this.contractToken.methods.remove(Number(fileId))
                    .send().then((transaction) => {

                this.transactionId = transaction.transactionHash;
                this.transactionIdTitle = this.transactionId.slice(0, 5) + '...' + this.transactionId.slice(-5);

                // получаем данные
                this.start();

            }).catch(() => {

                this.$store.dispatch('app/setAlert', { 
                    is_alert:  true,
                    type:  "danger",
                    message: "Ошибка отправки транзакции!",
                });
                        
            }).finally(() => {

                this.loading = false;
            });
    
        },


        contentScroll() {


            let block = document.getElementById('content-scroll');

            // получение последнего элемента массива
            let newCloud = this.newCloud;
            let lastItem = newCloud[newCloud.length - 1];

            console.log("lastItemId: ");
            console.log(lastItem);

            if(typeof  lastItem !== "undefined" && typeof lastItem.fileId !== "undefined") {
                let lastItemId = Number(lastItem.fileId);
     
                let contentHeight = block.offsetHeight; 
                let yo = window.scrollY;
                let wh = window.innerHeight;
                let y = yo + wh;

        

                // если пользователь достиг конца
                if(y >= contentHeight && this.isScroll && lastItemId > 0)
                {
                    //загружаем новое содержимое в элемент
                   this.getCloudDesc(lastItemId, 5);


                    this.isScroll = false;

                    setTimeout(() => {
                        this.isScroll = true;
                    }, 1000); 
                }

            } // end if

        },

        start() {

            // получаем данные
            this.getCloudDesc(this.countCloud, 5);

        }
 
     },

    watch: {
        '$route'(){
            
            this.start();
        }
    },

    data: () => ({
        loading: false,

        newCloud: [],
        cloud: [],

        limit: 512, // кол-во символов превью

        allcloud: [],

        token:null,

       // cloudAddress: "0x31f34d7E4D475469A58c91C54fb42E5fbEba78C5",
       cloudAddress: "0xf3DB4c7a06a2154EbE1CD88024EF3641CB9b24e5",

        transactionId: "",
        transactionIdTitle: "",

        countCloud: 0,
        isScroll: true
     }),
     mounted() {

        console.log("this.currentAddress: "+this.currentAddress);
 

         if(this.currentAddress == "") {
            router.push("/connect");

        } else {

            window.onscroll = () => {

               // this.contentScroll();
            };

          //  this.getNote(1);

            this.getCountCloud(countCloud => {

                // сохраняем общее кол-во записей
                this.countCloud = countCloud;

                // получаем данные
                this.start();


                //this.getCloud(1, countCloud);
            });
 
         } // end if


        if(this.currentProfile === null) {
                
            this.$store.dispatch('app/setLoginModal', { 
                show:  true
            });
        }
 
    },

    beforeDestroy() {

        //  удаление бесконечной загрузки на скролин
      //  window.removeEventListener("scroll", this.contentScroll, false);

    }

 }
 </script>
 
 <style scoped lang="less">

.cloud {
    margin: 0;
    display: flex;
    flex-direction: column;

    h1 {
        padding: 0 10px;
        margin-top: 20px;

        i {
            font-size:18px;
        }
    }

    &__list {
        padding:10px;

        
        &-inp {

            border: 1px solid silver;
            padding: 10px;

            &-noencrypt {
                padding-bottom: 10px;
                font-weight: bold;
                font-size:14px;
                color: green;
            }

            &-link {
                margin-top: 20px;
                margin-bottom: 0;

                a {
                    color: black;
                    text-decoration: none;
                }

                i {
                    font-size:20px;
                }
            }

            input[type=file] {
                width:100%;
                padding:0;
                border: 0;
                margin-top:5px;
                margin-bottom:5px;
            }

            &-image {
                margin-top:10px;
                cursor: pointer;

                &-min {
                    max-width: 150px;
                }

                &-max {
                    width: 95%;
                    max-width:none;
                }
            }
        }

        &-item {
            border: 1px solid silver;
            border-radius: 5px;
            margin-bottom:10px;

            &-header {
                border-bottom: 1px solid silver;
            }
            
            &-title {
                font-size:16px;
                font-weight: bold;
                padding:10px;

                a {
                    color: black;
                }

            }

            &-text {
                &-container {
                    width: 100%;
                }

                font-size:14px;
                padding:10px;

                width: 100%;

                a {
                    color: blue;
                }
            }

            &-create {
                font-size:12px;
                display: flex;
                justify-content: left;
                align-items: center;
                color: grey;
                padding:10px 10px 0 10px;
            }

            &-footer {
                display: flex;
                justify-content: space-between;
                padding:0;
            }


            &-date {
                font-size:12px;
                display: flex;
                justify-content: center;
                align-items: center;
                color: grey;
                padding:10px;

            }


            &-update {
                font-size:14px;
                padding:0;
            }
        }

    }

    &__buttons {

        display: flex;
        justify-content: space-around;


        a {
            display: flex;
            align-items: center;
            font-size:14px;
            text-align: center;
        }

        i {
            font-size:20px;
        }

        &-item {
            flex-basis: 45%;
        
        }
    }
 }

 </style>