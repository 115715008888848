<template>
   <div class="home">
        
        <h1>Мир тёмного аниме</h1>
        <h2>Компьютерная игра в подземном мире где люди выживают в полутьме, 
            где бушуют страсти, где стяжается стремление к свету!</h2>

            <p>Пересонажи и ресурсы игры ограничены при помощи технологии NFT. 
   Ресурсы и персонажей игры можно преобрести на nft-маркетплейсе и позже использовать их в игре.
   Также там можно преобрести различные фан-арты в виде nft-коллекций.
            </p>

            <p>С дорожной картой будущей игры можно ознакомиться по ссылке.
            </p>
 
  </div>
</template>

<script>
import router from '@/router/router'

export default {
    name: 'Home',
    props: {
    },
    components: {
    },
    methods: {

    },
    data: () => ({
    }),
    mounted() {

        let hash = location.hash;

        // редирект с 404 страниц
        if(hash !== "") {

            hash = hash.substring(1);
            router.push(hash);
        } else {

            // получаем главную страницу

        } // end if

    }
}
</script>

<style scoped lang="less">
.home {
  margin: 20px 0 0;

    .logo {
        margin: 0 auto;
        width:90%;

        &-title {
            
            font-family: 'SF Chrome Fenders', sans-serif;
            font-size:38px;
        }

        span {
            font-size: 14px;
        }
    }
}
</style>