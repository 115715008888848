<template>
    <div class="update-file">
        <Loader :loading="loading" />

        <Transaction :id="transactionId"/>

        <h1><i class="las la-cloud-upload-alt"></i> Обновить файл</h1>

        <form class="pure-form pure-form-stacked">
            <fieldset>

                <label for="stacked-title">Заголовок</label>
                <input 
                    type="text" 
                    id="stacked-title" 
                    placeholder="Заголовок" 
                    :value="title"
                    @input="event => title = event.target.value"
                />


                <div class="update-file__inp">
                    <input type="file" @change="readFile" />

                    <div v-if="name !== ''">
                        Имя: <b>{{name}}</b> <br />
                        Размер:  <b>{{formatBytes(size)}}</b> <br />
                        Тип:  <b>{{type}}</b>
                    </div>

                    <div class="update-file__inp-image" @click="fileImageMin" v-if="is_image">
                        <img :src="content"  
                            :class="is_image_min?'update-file__inp-image-min':'update-file__inp-image-max'"
                        />
                    </div>

                    <div class="update-file__inp-link" v-if="name !== ''">
                        <i class="las la-file-download"></i> <a :download="name" :href="content">Скачать</a>
                    </div>
                </div>



                <div v-if="!no_encrypt">
                    <label for="stacked-key">Ключ шифрования</label>
                    <select name="folder" id="stacked-key" v-html="htmlOptions" @change="changeKey">
                    </select>

                    <br />
                    <router-link to="/create-profile-key" class="pure-form-message">
                        Добавить ключ шифрования
                    </router-link>
                </div>




                <label for="stacked-remember" class="pure-checkbox">
                    <input type="checkbox" id="stacked-remember" v-model="no_encrypt" /> * Не шифровать файл
                </label>

                <button type="submit" class="pure-button pure-button-primary"  @click="updateFile">Сохранить</button>
            </fieldset>
        </form>

        <p>
            * Файл не будет зашифрован, и его содержимое будет доступно всем по ссылке (без пароля)
        </p>

        <div class="back">
            <div @click="back"><i class="las la-arrow-left"></i>&nbsp; Назад</div>
        </div>
    
    </div>
    </template>
    <script>
    
    import router from '@/router/router'
    import Utils from '@/utils/utils'
    
    import Loader from '@/components/blocks/Loader.vue'
    import Transaction from '@/components/blocks/Transaction.vue'

    import CloudABI from '@/abi/cloud.json'
    
    import { mapState, mapGetters } from 'vuex'
    
    export default {
        name: "CloudUpdateFile",
        components: {
            Loader,
            Transaction
        },
        props: {
            id: String
        },
        computed: {
            ...mapState({
                "web3": state => state.app.web3,
                "currentAddress": state => state.app.currentAddress,
                "currentProfile": state => state.app.currentProfile,
                "currentPass": state => state.app.currentPass,
            }),
    
            ...mapGetters({
            }),

            

            folders() {
                let folders = this.currentProfile.data.folders;

                let keys = this.currentProfile.data.keys;


                for (let i in folders) {

                    folders[i]["keylist"] = [];

                    for (let key in keys) {
                        if(keys[key].folder == folders[i].id) {
                            folders[i]["keylist"].push(keys[key]);
                        }
                    }
                }

                return folders;

            },

            defKeyId() {

                if(this.keyId == "") {

                    let keys = this.currentProfile.data.keys;

                    let k = "";
                    for (let key in keys) {
                        if(keys[key].type == 's') {
                            k = key;
                            break;
                        }
                    }

                    return k;

                }

                return this.keyId;

            },

            htmlOptions() {
                
                let res = '<option value="0" disabled>- Без папки -</option>';

                for(let item of this.folders) {

                    res += '<option value="'+item.id+'" key="f'+item.id+(new Date().getTime())+'" disabled>'+item.name+'</option>';

                    for (let key in item.keylist) {
                        // только симетричные ключи
                        if(item.keylist[key].folder == item.id  && item.keylist[key].type == 's') {

                            let selected = item.keylist[key].login == this.defKeyId?"selected":"";

                            res += '<option value="'+item.keylist[key].login+'" key="k'+item.keylist[key].login+(new Date().getTime())+'" '+selected+'> - '+item.keylist[key].name+'</option>';

                        }
                    }
                }

                return res;

            },
            
            is_image() {
               return this.type == "image/jpeg" || this.type == "image/png" || this.type == "image/gif";
            },
            
            resultJson(){

                let keys = this.currentProfile.data.keys;


                let fileObj = {
                        title: this.title,
                        name: this.name,
                        size: this.size,
                        type: this.type,
                        content: this.content
                };

                let jsonData = JSON.stringify(fileObj);


                if(this.no_encrypt) {
                    return jsonData;

                } else if(!this.no_encrypt && this.defKeyId !== "" && typeof keys[this.defKeyId] !== "undefined") {


                    let password = keys[this.defKeyId].password;
                    let type = keys[this.defKeyId].type;
                    let algo = keys[this.defKeyId].algo;

                    if(type == "s" && algo == "aes") {

                        const encryptedText = this.$CryptoJS.AES.encrypt(jsonData, password).toString();

                        console.log("s:aes:"+this.defKeyId+";"+encryptedText);

                        return "s:aes:"+this.defKeyId+";"+encryptedText;

                    }
                }

                return "err";
                
            }                          
        },
        methods: {
    
            back() {
                router.push("/cloud");
            },

            
            changeKey(e) {
                this.keyId = e.target.value;

            },

            fileImageMin() {
                this.is_image_min = !this.is_image_min;
            },

            formatBytes(size) {
                return Utils.formatBytes(size);
            },

            getKeyPassword(keyId) {
                let profile = this.currentProfile;
                let keys = profile.data.keys;

                return keys[keyId].password;
            },


            readFile(event) {
                let file = event.target.files[0];
                this.file = file;

                console.log(file);

                
                this.name = file.name;
                this.size = file.size;
                this.type = file.type;
                this.content = "";


                let reader = new FileReader();

                reader.readAsDataURL(file);

                reader.onload = () => {

                    this.content = reader.result;

                    console.log(this.content);
                };

                reader.onerror = function() {
                   console.log(reader.error);
                };

            },


            getFile(fileId) {

                this.token = new this.web3.eth.Contract(CloudABI, this.cloudAddress, { from: this.currentAddress });

                this.token.methods.read(fileId).call().then((res) => {

                    console.log(res);

                    try {

                        // если текст не шифрованный парсим Json
                        if(res.text[0] == "{") {

                            let obj = JSON.parse(res.text);

                            this.title = obj.title;
                            this.name  = obj.name;
                            this.size  = obj.size;
                            this.type  = obj.type;
                            this.content  = obj.content;

                            this.no_encrypt = true;

                        // если текст шифрованный, дешифруем и парсим Json
                        } else {
                            

                            let codes = res.text.split(';');
                            let subcodes = codes[0].split(':');

                            if(subcodes[0] == 's' && subcodes[1] == 'aes') {
                                const decryptedText = this.$CryptoJS.AES.decrypt(codes[1], this.getKeyPassword(subcodes[2])).toString(this.$CryptoJS.enc.Utf8);

                                console.log(decryptedText);
                            

                                let obj = JSON.parse(decryptedText);

                                this.title = obj.title;
                                this.name  = obj.name;
                                this.size  = obj.size;
                                this.type  = obj.type;
                                this.content  = obj.content;
                            }

                        }

                        this.keyId = res.keyId;
                        this.ts = res.ts;
                        this.ts_update = res.ts_update;

                    } catch (error) {

                        this.title = "Не удалось расшифровать!";
                    }

                }).catch(() => {

                    this.$store.dispatch('app/setAlert', { 
                        is_alert:  true,
                        type:  "danger",
                        message: "Ошибка получения данных!",
                    });

                    
                }).finally(() => {

                   this.loading = false;
                });


            },
    
 
            updateFile(event) {
                event.preventDefault();

                this.loading = true;

                this.contractToken = new this.web3.eth.Contract(CloudABI, this.cloudAddress, { from: this.currentAddress });

                this.contractToken.methods.update(Number(this.id), "", this.resultJson, this.defKeyId).send().then((transaction) => {

                    this.transactionId = transaction.transactionHash;

                }).catch(() => {

                    this.$store.dispatch('app/setAlert', { 
                        is_alert:  true,
                        type:  "danger",
                        message: "Ошибка обновления данных!",
                    });
                        
                }).finally(() => {

                    this.loading = false;
                });
              
    
            }           
    
        },
        data: () => ({

            
            no_encrypt: false,

            title:"",
            name: "",
            size: "",
            type: "",
            content: "",
            is_image_min: true,

            
            contractToken: "",

            preview: "",
            text: "",
            keyId: "",

            // cloudAddress: "0x31f34d7E4D475469A58c91C54fb42E5fbEba78C5",
            cloudAddress: "0xf3DB4c7a06a2154EbE1CD88024EF3641CB9b24e5",

            transactionId: "",

            loading: false,
    
            is_success: false
    
        }),
        mounted() {
    
            if(this.currentAddress == "") {
                router.push("/connect");
            } else {
                this.getFile(Number(this.id));
            } // end if

            if(this.currentProfile === null) {
                
                this.$store.dispatch('app/setLoginModal', { 
                    show:  true
                });
            }
    
        }
    }
    </script>
    
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
    
.update-file {
    margin: 0 auto;
    margin-top:10px;
    display: flex;
    flex-direction: column;
    max-width: 500px;


    &__inp {

        border: 1px solid silver;
        padding: 10px;
        margin-top:10px;

        &-link {
            margin-top: 20px;
            margin-bottom: 10px;

            a {
                color: black;
                text-decoration: none;
            }

            i {
                font-size:20px;
            }
        }

        input[type=file] {
            width:100%;
            padding:0;
            border: 0;
            margin-top:5px;
            margin-bottom:5px;
        }

        &-image {
            margin-top:10px;
            cursor: pointer;

            &-min {
                max-width: 150px;
            }

            &-max {
                width: 95%;
                max-width:none;
            }
        }

    }
    
    input[type=button] {
        padding: 5px 15px;
        margin-top:15px;
        width:auto;
        cursor: pointer;
    }
    
    input[type=checkbox] {
        width: 20px !important; 
    }
    
    select {
        width:62%;
        padding: 5px 15px;
        margin-top:5px;
        cursor: pointer;
    }

    label {
        padding:5px;
        margin-top:10px;
    }

    
    @media (max-width: 768px) {
        select {
            width:100%;
        }
    
        input {
            width:100%;
        }
    }
        
}
    
</style>