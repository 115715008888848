<template>
    <div class="mail">
         <div class="logo">
             <div class="logo-title">W3UN MAIL</div>
             <span>Децентрализованная электронная почта</span>
         </div>
  
   </div>
 </template>
 
 <script>
 import router from '@/router/router'
 
 export default {
     name: 'Mail',
     props: {
     },
     components: {
     },
     methods: {
 
     },
     data: () => ({
     }),
     mounted() {
 
         let hash = location.hash;
 
         // редирект с 404 страниц
         if(hash !== "") {
 
             hash = hash.substring(1);
             router.push(hash);
         } else {
 
             // получаем главную страницу
 
         } // end if
 
     }
 }
 </script>
 
 <style scoped lang="less">
 .mail {
   margin: 20px 0 0;
 
   .logo {
        margin: 0 auto;
        width:90%;

        &-title {
            
            font-family: 'SF Chrome Fenders', sans-serif;
            font-size:34px;
        }

        span {
            font-size: 14px;
        }
    }
 }
 </style>