<template>
    <div class="profile">

        <h1>Профиль</h1>

        <div class="profile__buttons">
            <div class="profile__buttons-item">
                <router-link to="/create-profile-key" class="pure-button button-secondary" title="Создаь ключ"><i class="las la-key"></i></router-link>
            </div>
            <div class="profile__buttons-item">
                <router-link to="/create-profile-folder" class="pure-button button-secondary" title="Создаь папку"><i class="las la-folder"></i></router-link>
            </div>
            <div class="profile__buttons-item">
                <router-link to="/profiles" class="pure-button pure-button-primary" title="Все профили"><i class="las la-list"></i></router-link>
            </div>
            <div class="profile__buttons-item">
                <router-link to="/create-profile" class="pure-button pure-button-primary" title="Создать профиль"><i class="las la-plus-square"></i></router-link>
            </div>
            <div class="profile__buttons-item" v-if="currentProfile !== null">
                <router-link  :to="'/update-profile/'+currentProfile.id" class="pure-button" title="Редактировать профиль"><i class="las la-pencil-alt"></i></router-link>
            </div>
        </div>


        <div v-if="currentProfile === null" class="profile__info">
            <p>Вы, не авторизованы в профиле, чтение зашифрованных сообщений невозможно.</p>
            <button  
                    class="pure-button pure-button-primary"
                    @click="openProfile">
                    <i class="las la-key"></i> Войти в профиль
            </button>
        </div>
        <div v-else class="profile__info">
            <div class="profile__info-item">
                Имя: <b>{{ currentProfile.name }}</b>
            </div>
            <div class="profile__info-item">
                Логин: <b>{{currentProfile.login}}</b>
            </div>
            <br />

            <button  
                    class="pure-button pure-button-primary"
                    @click="exitProfile">
                    <i class="las la-sign-out-alt"></i> Выйти из профиля
            </button>

            <h2>Папки и ключи</h2>

            <ul class="push">
               <li v-for="(item, index) in folders" :key="'pf'+index+(new Date().getTime())">
                <button  class="pure-button" @click="updateFolder(item.id)"><i class="las la-pen"></i></button>&nbsp; <button class="button-error pure-button"  @click="removeFolder(item.id)"><i class="las la-trash"></i></button>&nbsp;  <b>{{item.name}}</b>

                    <ul class="push">
                        <li v-for="(itemkey, indx) in item.keylist" :key="'pfi'+indx+(new Date().getTime())">
                            <button class="pure-button" @click="updateKey(itemkey.login)"><i class="las la-pen"></i></button>&nbsp; 
                            <button  class="button-error pure-button" @click="removeKey(itemkey.login)">
                                <i class="las la-trash"></i>
                            </button>&nbsp;  {{ itemkey.name + " (" + itemkey.login + ") " }} 
                        </li>
                    </ul>
               </li>
            </ul>


            <h2>Ключи вне папок</h2>
            <ul class="push">
                <li v-for="item of keys_no_folder" :key="'knf'+item.login+(new Date().getTime())">
                    <button class="pure-button"><i class="las la-pen"></i></button>&nbsp; 
                    <button  class="button-error pure-button" @click="removeKey(item.login)">
                        <i class="las la-trash"></i>
                    </button>&nbsp;  {{ item.name + " (" + item.login + ") " }} 
                </li>
            </ul>

        </div>
  
   </div>
 </template>
 
 <script>
 import router from '@/router/router'
 import IDB from '@/utils/idb'

 import { mapState, mapGetters } from 'vuex'


 export default {
     name: 'Profile',
     components: {

    },
     props: {
        id: String
     },
     computed: {

            ...mapState({
                "web3": state => state.app.web3,
                "currentAddress": state => state.app.currentAddress,
                "currentProfile": state => state.app.currentProfile,
                "currentPass": state => state.app.currentPass
            }),
    
            ...mapGetters({
            }),

            folders() {

                let folders = this.currentProfile.data.folders;

                let keys = this.currentProfile.data.keys;


                for (let i in folders) {

                    folders[i]["keylist"] = [];

                    for (let key in keys) {
                        if(keys[key].folder == folders[i].id) {
                            folders[i]["keylist"].push(keys[key]);
                        }
                    }
                }

                return folders;

            },

            keys_no_folder() {
                let keys = this.currentProfile.data.keys;

                let arr_keys = [];
                for (let key in keys) {
                        if(keys[key].folder == 0) {
                            arr_keys.push(keys[key]);
                        }
                    }

                return arr_keys;

            }
     
     },
     methods: {

        openProfile() {
            if(this.currentProfile === null) {
                
                this.$store.dispatch('app/setLoginModal', { 
                    show:  true
                });
            }
        },

        exitProfile() {

            this.$store.dispatch('app/setProfile', {     
                profile: null,
                password: null
            });
        },

        removeFolder(id) {
            if(confirm("Удалить папку?")) {
     

                let profile = this.currentProfile;
                let profileId = profile.id;

                let keys = profile.data.keys;
                let folders = profile.data.folders;

                // удаляем папку из списка папок
                folders = folders.filter(item => item.id !== id);

                // перемещаем все ключи из удалённой папки в корень
                for (let key in keys) {
                    if(keys[key].folder == id) {
                        keys[key].folder = 0;
                    }
                }
              
                let data = { 
                        text: profile.text, 
                        keys, 
                        folders
                };

                let jsonData = JSON.stringify(data);
                const encryptedText = this.$CryptoJS.AES.encrypt(jsonData, this.currentPass).toString();

                let saveData = { name: profile.name, login: profile.login, data: encryptedText  }; 

                // ------------- Запись данных
                IDB.saveProfile(profileId, saveData).then(() => {


                    let myprofile = {
                        id: profileId,
                        name: profile.name,
                        login: profile.login,
                        data
                    };

                    this.$store.dispatch('app/setProfile', { 
                        profile: myprofile,
                        password: this.currentPass,
                    });

                    router.push("/profile");

                    this.$store.dispatch('app/setAlert', { 
                        is_alert:  true,
                        type:  "success",
                        message: "Данные сохранены!",
                    });
                           

                }).catch(() => {

                    this.$store.dispatch('app/setAlert', { 
                        is_alert:  true,
                        type:  "danger",
                        message: "Ошибка сохранения данных!",
                    });
                            
                });

            }
        },

        removeKey(login) {
            if(confirm("Удалить ключ? Вся информация зашифрованная этим ключом будет потерена!")) {

                let profile = this.currentProfile;
                let profileId = profile.id;

                let keys = profile.data.keys;
                let folders = profile.data.folders;


                // удаляем ключ
                if(typeof keys[login] !== "undefined") {
                    delete keys[login];
                }
              
                let data = { 
                        text: profile.text, 
                        keys, 
                        folders
                };

                let jsonData = JSON.stringify(data);
                const encryptedText = this.$CryptoJS.AES.encrypt(jsonData, this.currentPass).toString();

                let saveData = { name: profile.name, login: profile.login, data: encryptedText  }; 

                // ------------- Запись данных
                IDB.saveProfile(profileId, saveData).then(() => {


                    let myprofile = {
                        id: profileId,
                        name: profile.name,
                        login: profile.login,
                        data
                    };

                    this.$store.dispatch('app/setProfile', { 
                        profile: myprofile,
                        password: this.currentPass,
                    });

                    router.push("/profile");

                    this.$store.dispatch('app/setAlert', { 
                        is_alert:  true,
                        type:  "success",
                        message: "Данные сохранены!",
                    });
                           

                }).catch(() => {

                    this.$store.dispatch('app/setAlert', { 
                        is_alert:  true,
                        type:  "danger",
                        message: "Ошибка сохранения данных!",
                    });
                            
                });

            }
        },

        updateFolder(id) {
            router.push("/update-profile-folder/"+id);
        },

        updateKey(id) {
            router.push("/update-profile-key/"+id);
        },


     },
     data: () => ({

    }),

    mounted() {

        console.log("this.currentAddress: "+this.currentAddress);
 

        if(this.currentAddress == "") {
          //  router.push("/connect");

        }

        console.log(this.currentProfile);
        
    }
 }
 </script>
 
 <style scoped lang="less">
 .profile {
    margin: 0 auto;
    margin-top:10px;
    display: flex;
    flex-direction: column;
    padding:15px;

    h2 {
        font-size: 16px;
        border-top: 1px solid silver;
        padding-top: 20px;
        margin-top: 30px;
        color: grey;
    }

    button {
        width:auto;
    }

    &__info {
        padding:20px;
        border:1px solid silver;
        border-radius: 5px;
        margin:25px 0;

        &-item {
            padding-top:10px;
        }
    }


    &__buttons {

        display: flex;
        justify-content:space-between;
        max-width: 350px;

        a {
            display: flex;
            align-items: center;
            font-size:14px;
            text-align: center;
        }

        i {
            font-size:20px;
        }

        &-item {
            flex-basis: 15%;
            max-width: 50px;
        
        }
    }

    .push {
        list-style: none;
    }
    .push li {
        position: relative;
        padding: 8px 0 20px 30px;
        font-size: 15px;
        cursor: pointer;
        margin-left: -30px;

        button {
            padding: 5px;
            border-radius: 5px;

            i {
                font-size: 16px;
            }
        }
    }
    .push li:before {
        position: absolute;
        width: 7px;
        height: 7px;
        border-radius: 50%;
        background: #4F5151;
        content: "";
        left: 0;
        transition: .3s ease-in-out;
        top: 27px;
    }
    .push li:after {
        position: absolute;
        border-left: 1px dotted #4F5151;
        width: 1px;
        bottom: -12px;
        content: "";
        left: 3px;
        top: 48px;
    }
    .push li:hover:before{box-shadow: 0 0 0 10px rgba(0,0,0,.2)}
    .push li:last-child:after {content: none;}

}
</style>