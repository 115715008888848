<template>
    <div class="new-transaction">

        <Loader :loading="loading" />

        <h1>Отправить MATIC</h1>


        <Alert v-if="is_error" type="danger" message="Ошибка отправки транзакции!"/>
           <div v-if="transactionId" class="b-alert b-alert-success">
                Транзакция успешно отправлена!<br />
                О ходе выполненения транзакции можно узнать на сайте 
                <a :href="'https://polygonscan.com/tx/'+transactionId" target="_blank">polygonscan.com</a><br />
                ID транзакции: <b>{{transactionIdTitle}}  &nbsp;<i class="las la-copy" @click="() => copy()" title="Скопировать в буфер обмена"></i></b>

            </div>


            <div class="new-transaction-scan-qr">
                <router-link to="/scan-qr"><i class="las la-qrcode"></i> Сканировать QR-код</router-link>
            </div> 
        
            <form class="pure-form pure-form-aligned new-transaction__form">
                <fieldset>
                    <div class="pure-control-group">
                        <label for="aligned-address">Адрес кошелька</label>
                        <input type="text" 
                            id="aligned-address" 
                            placeholder="Например: 0x3C01C361971779ACfdeb47302144B4f9BB82F59a" 
                            :value="address"
                            @input="event => address = event.target.value"
                            />
                        <span class="pure-form-message-inline"></span>
                    </div>
                    <div class="pure-control-group">
                        <label for="aligned-amount">Сумма</label>
                        <input type="text" 
                            id="aligned-amount" 
                            placeholder="Например: 1.5" 
                            class="new-transaction-form__amount" 
                            :value="amount"
                            @input="event => amount = event.target.value"
                        />
                    </div>
                    <div class="pure-controls">
                        <button type="submit" class="pure-button pure-button-primary" @click="sendTransaction"><i class="las la-arrow-up"></i> Отправить</button>
                        <button class="pure-button" @click="back"><i class="las la-times"></i> Отмена</button>
                    </div>
                </fieldset>
            </form>


    </div>
</template>
<script>
    
import router from '@/router/router'
    
    //import axios from 'axios'

   // import FCLI from '@/utils/fcli'

import Loader from '@/components/blocks/Loader.vue'
import Alert from '@/components/blocks/Alert.vue'
    
import { mapState, mapGetters } from 'vuex'
    
export default {
        name: "NewTransaction",
        components: {
            Loader,
            Alert
        },
        props: {
            addr: String
        },
        computed: {
            ...mapState({
                "web3": state => state.app.web3,
                "currentAddress": state => state.app.currentAddress,
            }),
    
            ...mapGetters({
               // "currentServer": "app/currentServer",
              //  "user_id": "app/user_id",
               // "session_id": "app/session_id",
               // "temp_session_id": "app/temp_session_id"
            })
     
        },
        methods: {
    
            sendTransaction(event) {
                event.preventDefault();

                this.loading = true;

                try {


                    this.web3.eth.sendTransaction({ 
                            from: this.currentAddress, 
                            gasPrice: "20000000000", 
                            gas: "21000", 
                            to: this.address, 
                            value: this.web3.utils.toWei(this.amount, 'ether'), 
                            data: "" 
                    }).then(transaction => {

                        console.log(transaction);

                        this.transactionId = transaction.transactionHash;
                        this.transactionIdTitle = this.transactionId.slice(0, 5) + '...' + this.transactionId.slice(-5)

                        this.address = "";
                        this.amount = "";
                        this.is_error = false;

                     }).catch(error => {

                        console.log(error);

                        this.is_error = true;

                    }).finally(() => {
                        console.log("Transaction final");

                        this.loading = false;
                    
                    });

                } catch (e) {
                    // инструкции для обработки ошибок
                    console.log(e); // передать объект исключения обработчику ошибок

                    this.is_error = true;
                    this.loading = false;
                }


                return false;
            },

            copy() {

                if(this.currentAddress) {

                    const el = document.createElement('textarea');
                    el.value = this.currentAddress;
                    el.setAttribute('readonly', '');
                    el.style.position = 'absolute';
                    el.style.left = '-9999px';
                    document.body.appendChild(el);
                    el.select();
                    document.execCommand('copy');
                    document.body.removeChild(el);

                    alert("Хеш скопирован в буфер обмена!");
                }
            },

            back() {
                router.push("/wallet");
            }

        },
        data: () => ({
            address: "",
            amount: "",
            transactionId: "",
            transactionIdTitle: "",
            is_error: false,
            loading: false

        }),
        mounted() {

            // адрес из url
            this.address = this.addr;

            if(this.currentAddress == "") {
                router.push("/connect");
            } 
    
        }
}
</script>
    
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.new-transaction {
    margin: 0;
    margin-top:10px;

    padding: 15px;


    .error {
        width:100%;
    }

    &-scan-qr {
        padding-top: 25px;

        i {
            font-size: 20px;
        }

        a{
            color: black;
            text-decoration: none;
            font-size: 14px;

            &:hover {
                color: grey;
                text-decoration: none;
            }
        }
    }

    &__form {
        margin-top:10px;
        width: 100%;



        input[type=text] {
            width: 72%;
            max-width: 700px;

            @media (max-width: 470px) { 
                width: 100%;
            }
        }

        &-amount {
            max-width: 200px !important;
        }

        button {
            margin-right:10px;

            @media (max-width: 470px) { 
                width: 100%;
                margin-right:0;
                margin-bottom:10px;
            }
        }
        
    }
}
    
</style>