<template>
    <div class="create-profile">
        <Loader :loading="loading" />

        <Alert v-if="is_error" type="danger" message="Ошибка отправки транзакции!"/>
        <Transaction :id="transactionId"/>

        <h1>Новый профиль</h1>

        <form class="pure-form pure-form-stacked">
            <fieldset>
                <label for="stacked-name">Имя</label>
                <input 
                        type="text" 
                        id="stacked-name" 
                        placeholder="Любые символы..." 
                        :value="name"
                        @input="event => name = event.target.value"
                />
                <span class="pure-form-message">Имя профиля будет доступно всем пользователям вашего устройства, без пароля. </span>

                <label for="stacked-login">Логин</label>
                <input 
                        type="text" 
                        id="stacked-login" 
                        placeholder="Латинские сиволы, цифры, _" 
                        :value="login"
                        @input="event => login = event.target.value"
                />

                <span class="pure-form-message">Логин профиля будет доступно всем пользователям вашего устройства, без пароля. </span>

                <label for="stacked-password">Мастер-пароль</label>
                <input 
                        type="text" 
                        id="stacked-password" 
                        placeholder="Сгененрирйте или придумайте пароль" 
                        :value="password"
                        @input="event => password = event.target.value"
                />


                <span class="pure-form-message"><b>Внимание!</b> Если мастер-пароль будет утерен, все сообщения привязанные к ключам профиля и настройки профиля не смогут быть прочитаны! Сохраните пароль в надёжном месте!</span>

                <label for="stacked-text">Комментарий</label>
                <textarea 
                        placeholder="Любые символы..." 
                        id="stacked-text"
                        :value="text"
                        @input="event => text = event.target.value"
                ></textarea>

                <span class="pure-form-message">Комментарий сможет прочитать, только тот, кто знает мастер-пароль.</span>

                <button type="submit" class="pure-button pure-button-primary"  @click="createProfile">Создать</button>
            </fieldset>
        </form>


        <div class="back">
            <div @click="back"><i class="las la-arrow-left"></i>&nbsp; Профили</div>
        </div>
    
    </div>
</template>
<script>
    
import router from '@/router/router'




import Loader from '@/components/blocks/Loader.vue'
import Alert from '@/components/blocks/Alert.vue'

import IDB from '@/utils/idb'

    
import { mapState, mapGetters } from 'vuex'
    
  //  import locales from '@/utils/locales';
    
export default {
        name: "CreateProfile",
        components: {
            Loader,
            Alert
        },
        props: {
        },
        computed: {
            ...mapState({
                "web3": state => state.app.web3,
                "currentAddress": state => state.app.currentAddress
            }),
    
            ...mapGetters({
            })            
        },
        methods: {
    
            back() {
                router.push("/profiles");
            },
    
            createProfile(event) {
                event.preventDefault();


                let { name, login, password, text } = this;

                let data = { 
                    text, 
                    keys: {}, 
                    folders: [
                        { id: 1, parent: 0, name: "Заметки"},
                        { id: 2, parent: 0, name:"Электронная почта"},
                        { id: 3, parent: 0, name:"Веретено"},
                        { id: 4, parent: 0, name:"Важные данные"},
                        { id: 5, parent: 0, name: "Доступы к сайтам"},

                    ]
                };

                let jsonData = JSON.stringify(data);
                const encryptedText = this.$CryptoJS.AES.encrypt(jsonData, password).toString();

                let saveData = { name, login, data: encryptedText  }; 

                // ------------- Запись данных
                IDB.addProfile(saveData).then((id) => {

                    let profile = {
                        id,
                        name,
                        login,
                        data
                    };


                    this.$store.dispatch('app/setProfile', { 
                        profile,
                        password,
                    });

                    router.push("/profiles");

                }).catch(() => {

                    this.$store.dispatch('app/setAlert', { 
                        is_alert:  true,
                        type:  "danger",
                        message: "Ошибка записи в локальную базу данных!",
                    });
                    
                });

                
        
            }
            
    
        },
        data: () => ({

 


            name: "",
            login: "",
            password: "",
            text: "",

            loading: false,
    
            is_success: false,
            is_error: false
    
        }),
        mounted() {
    
    
            if(this.currentAddress == "") {
               // router.push("/connect");
            } // end if
    
        }
}
</script>
    
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
    
.create-profile {
    margin: 0;
    margin-top:10px;
    display: flex;
    flex-direction: column;

    &__header {
        padding-top: 5px;
        padding-bottom: 15px;
    }

    &__content {
        border-radius:15px 15px 0 0;
        padding:10px 15px 15px 15px;
            

        h2 {
            font-size:18px;
            margin-top:30px;
        }
    }
    

        h3 {
            font-size: 16px;
            margin-top:20px;
        }
    
        textarea {
            width:100%;
            padding:10px;
            border: 1px solid silver;
            margin-top:5px;
            height: 150px;
        }
    
        input {
            width:62%;
            padding:10px;
            border: 1px solid silver;
            margin-top:5px;
        }
    
        input[type=button] {
            padding: 5px 15px;
            margin-top:15px;
            width:auto;
            cursor: pointer;
        }
    
        input[type=checkbox] {
            width: 20px !important; 
        }
    
        select {
            width:62%;
            padding: 5px 15px;
            margin-top:5px;
            cursor: pointer;
        }

        label {
            padding:5px;
            margin-top:10px;
        }

    
        @media (max-width: 768px) {
            select {
                width:100%;
            }
    
            input {
                width:100%;
            }
        }
        
    }
    
    </style>