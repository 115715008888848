<template>
    <div class="alert-modal-container" v-if="is_alert">
        <div :class="'alert-modal alert-modal-'+type">
            <div class="alert-modal-header" @click="close"><i class="las la-times"></i></div>
            <div class="alert-modal-body" v-html="message"></div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
    name: 'AlertModal',
    props: {
    },
    methods: {
        close(){
            this.$store.dispatch('app/setAlert', { 
                is_alert:  false,
                type:  "danger",
                message: ""
            });
        }
    },
    computed: {
        ...mapState({
            "is_alert":  state => state.app.is_alert,
            "type":  state => state.app.alert_type,
            "message":  state => state.app.alert_message,
        }),
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
/*** Alerts ***/

.alert-modal {
    padding: 0;
    color: inherit;
    background-color: #0000;
    border: 1px solid  #0000;
    border-radius: 15px;
    margin:0 auto;
    width: 98%;
    max-width:500px;
    opacity: 0.95;
    z-index: 11000;

    &-container {
        position: fixed;
        bottom: 10px;
        left:0;
        width: 100%;
        padding:0;
    }

    &-header {
        font-size:20px;
        text-align: right;
        padding-right: 10px;
        padding-top: 10px;

    }

    &-body {
        padding: 20px;
    }

    &-success {
        color: #0f5132;
        background-color: #d1e7dd;
        border-color: #badbcc;
    }

    &-primary {
        color: #084298;
        background-color: #cfe2ff;
        border-color: #b6d4fe;
    }

    &-secondary {
        color: #41464b;
        background-color: #e2e3e5;
        border-color: #d3d6d8;
    }

    &-danger {
        color: #842029;
        background-color: #f8d7da;
        border-color: #f5c2c7;
    }

    &-warning {
        color: #664d03;
        background-color: #fff3cd;
        border-color: #ffecb5;
    }

    &-dark {
        color: #141619;
        background-color: #d3d3d4;
        border-color: #bcbebf;
    }

    &-info {
        color: #055160;
        background-color: #cff4fc;
        border-color: #b6effb;
    }

}

</style>