<template>
    <div class="profiles">

        <h1>Профили</h1>

        <div class="profiles__buttons">
            <!--div class="profiles__buttons-export">
                <router-link to="/export-profile"><i class="las la-file-export"></i> Экспорт</router-link>
            </div>
            <div class="profiles__buttons-import">
                <router-link to="/import-profile"><i class="las la-file-import"></i> Импорт</router-link>
            </div-->
            <div class="profiles__buttons-new">
                <router-link to="/create-profile" class="pure-button pure-button-primary"><i class="las la-plus-square"></i> Создать</router-link>
            </div>
        </div>


        <div class="profiles__list">
            <div class="profiles__list-item"  v-for="(item, index) in profiles" :key="'pl'+index+(new Date().getTime())"> 
                <div class="profiles__list-item-header">
                    <div class="profiles__list-item-title">Имя: <b>{{ item.name }}</b></div>
                </div>
                <div class="profiles__list-item-text">
                    Логин: <b>{{item.login}}</b>
                </div>
                <div class="profiles__list-item-footer">
                        <div class="profiles__list-item-date"></div>
                        <div class="profiles__list-item-title">
                            <router-link class="pure-button" :to="'/update-profile/'+item.id">
                                <i class="las la-pencil-alt"></i>
                            </router-link>
                            &nbsp;
                            <a href="" class="pure-button" @click="e => removeProfile(e, item.id)">
                                <i class="las la-times"></i>
                            </a>
                        </div>
                </div>
            </div>
        </div>

        <div class="back">
            <div @click="back"><i class="las la-arrow-left"></i>&nbsp; Профиль</div>
        </div>


   </div>
 </template>
 
 <script>
 import router from '@/router/router'

 import { mapState, mapGetters } from 'vuex'

 import IDB from '@/utils/idb'

 export default {
     name: 'Profiles',
     components: {

    },
     props: {
        id: String
     },
     computed: {
            ...mapState({
                "web3": state => state.app.web3,
                "currentAddress": state => state.app.currentAddress
            }),
    
            ...mapGetters({
            })
     
     },
     methods: {

        back() {
            router.push("/profile");
        },

        getProfiles() {
            console.log("getProfiles");


            IDB.profiles().then((profiles) => {
                this.profiles = profiles;

            }).catch(() => {

                this.$store.dispatch('app/setAlert', { 
                    is_alert:  true,
                    type:  "danger",
                    message: "Ошибка локальной базы данных!",
                });

                            
            }).finally(() => {
    
            });

        },
        
        removeProfile(event, id) {
            event.preventDefault();

            if(confirm('Внимание! Удалив профиль, доступ к ключам и сообщениям привязанным к нему будет невозможен! Продолжить?')) {

                IDB.removeProfile(id).then(() => {
                // this.profiles = profiles;

                    this.getProfiles();

                }).catch(() => {

                    this.$store.dispatch('app/setAlert', { 
                        is_alert:  true,
                        type:  "danger",
                        message: "Ошибка локальной базы данных!",
                    });

                                
                }).finally(() => {
        
                });

            } // end if
        }


     },
    data: () => ({
        profiles: []

    }),

    mounted() {

        this.getProfiles();
        
    }
 }
 </script>
 
 <style scoped lang="less">
 .profiles {
    margin: 0;
    margin-top:10px;
    display: flex;
    flex-direction: column;

    &__buttons {

        display: flex;
        justify-content:space-between;
        padding: 20px 10px;

        a {
            display: flex;
            align-items: center;
        }

        i {
            margin-right:5px;
        }


        &-export {
            flex-basis: 25%;
            font-size:12px;
            border:1px solid silver;
            padding: 10px;
            border-radius: 5px;
        }

        &-import {
            flex-basis: 25%;
            font-size:12px;
            border:1px solid silver;
            padding: 10px;
            border-radius: 5px;
        }

        &-new {
            text-align:right;
            /*
            flex-basis: 25%;
            font-size:12px;
            border:1px solid silver;
            padding: 10px;
            border-radius: 5px;
            */
        }


        i  {
            font-size:18px;
        }

        /*
        a {
            text-decoration: none;
            color:black;
        }
        */
    }

    &__list {
        padding:10px;

        &-item {
            border: 1px solid silver;
            border-radius: 5px;
            margin-bottom:10px;

            &-header {
                border-bottom: 1px solid silver;
            }
            
            &-title {
                font-size:16px;
                padding:10px;

                a {
                    color: black;
                }

            }

            &-text {
                font-size:14px;
                padding:10px;

                a {
                    color: blue;
                }
            }

            &-create {
                font-size:12px;
                display: flex;
                justify-content: left;
                align-items: center;
                color: grey;
                padding:10px 10px 0 10px;
            }

            &-footer {
                display: flex;
                justify-content: space-between;
                padding:0;
            }


            &-date {
                font-size:12px;
                display: flex;
                justify-content: center;
                align-items: center;
                color: grey;
                padding:10px;

            }


            &-update {
                font-size:14px;
                padding:0;
            }
        }

    }

 }
 </style>