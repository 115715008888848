<template>
<div class="top-menu-list__body">
    <div class="top-menu-list-item" >
        <router-link to="/"  
            :class="currentRouteName == 'home'?'active':''" 
            @click="hideMenu">
            Главная
        </router-link>
    </div>

    <div class="top-menu-list-item" >
        <router-link to="/nft-marketplace"  
            :class="currentRouteName == 'nft-marketplace'?'active':''" 
            @click="hideMenu">
            NFT-Маркетплейс
        </router-link>
    </div>

    <div class="top-menu-list-item" >
        <router-link to="/map"  
            :class="currentRouteName == 'map'?'active':''" 
            @click="hideMenu">
            Дорожная карта
        </router-link>
    </div>

    <div class="top-menu-list-item" v-if="currentAddress == ''">
        <router-link to="/connect"
            :class="currentRouteName == 'connect'?'active':''" 
            @click="hideMenu">
             Привязать кошелёк
        </router-link>
    </div>
    <div class="top-menu-list-item" v-else>
        <router-link to="/connect"
            :class="currentRouteName == 'connect'?'active':''" 
            @click="hideMenu">
             Кошелёк 
        </router-link>
        {{substrAddress}}
    </div>

    <div class="top-menu-list-item" >
        <router-link to="/admin"  
            :class="currentRouteName == 'admin'?'active':''" 
            @click="hideMenu">
             Панель управления
        </router-link>
    </div>


</div>
</template>

<script>

import { mapState } from 'vuex'

export default {
    name: 'TopMenu',
    props: {
        currentRouteName: String,
        hideMenu: Function
    },
    computed: {
        ...mapState({
            "currentProfile": state => state.app.currentProfile,
            "currentAddress": state => state.app.currentAddress,
        }),

        substrAddress() {
            let str = this.currentAddress;
            return str.substr(0, 5) + "..." + str.substr(str.length - 5)
        }

    },   
    methods: {
    },     
    data: () => ({
    }),
    mounted() {
    }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
</style>