export default {
    SET_WEB3(state, payload) {
        state.web3 = payload.web3;
        state.currentAddress = payload.currentAddress;
    },

    SET_ALERT(state, payload) {

        state.is_alert = payload.is_alert;
        state.alert_type =  payload.type;
        state.alert_message = payload.message;
    },


    SET_PROFILE(state, payload) {

        state.currentProfile = payload.profile;
        state.currentPass =  payload.password;
    },

    SET_LOGIN_MODAL(state, payload) {

        state.is_login_modal = payload.show;
    },
};
