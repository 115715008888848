export default class DT {

    // получить день месяца и месяц
    // dt_str - дата в виде строки
    static getFormatDate(dt_str) {

        if(dt_str === '') return '';
        
        let dt = new Date(Number(dt_str) * 1000);
        
        let months = ["Января", "Февраля", "Марта", "Апреля", "Мая", "Июня", "Июля", "Августа", "Сентября", "Октября", "Ноября", "Декабря"];

        let day = dt.getDate();
        let year = dt.getFullYear();
        let num_month = dt.getMonth();

        let h = dt.getHours();
        let m = dt.getMinutes();


        h = (h < 10 ? '0' : '') + h;
        m = (m < 10 ? '0' : '') + m;

        return day + ' ' + months[num_month] + ' ' +year +' г. ' + h + ':' + m;
    }

    // преобразовние объекта Date в строку
    static dateToString(date) {

        let month = date.getMonth() + 1;
        month = (month < 10 ? '0' : '') + month;
        
        let d = date.getDate();
        d = (d < 10 ? '0' : '') + d;
        
        let year = date.getFullYear(); 

        return year+'-'+month+'-'+d;
    }
}