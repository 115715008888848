<template>
    <div :class="'b-alert b-alert-'+type"  v-html="message">
    </div>
</template>

<script>
export default {
    name: 'Alert',
    props: {
      type: String,
      message: String
    },
    computed: {
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less">
/*** Alerts ***/

.b-alert {
    position: relative;
    padding: 1rem 1rem;
    margin-bottom: 1rem;
    color: inherit;
    background-color: #0000;
    border: 1px solid  #0000;
    border-radius: 0.375rem;
	margin-top: 1rem;
    width: 90%;

    &-success {
        color: #0f5132;
        background-color: #d1e7dd;
        border-color: #badbcc;
    }

    &-primary {
        color: #084298;
        background-color: #cfe2ff;
        border-color: #b6d4fe;
    }

    &-secondary {
        color: #41464b;
        background-color: #e2e3e5;
        border-color: #d3d6d8;
    }

    &-danger {
        color: #842029;
        background-color: #f8d7da;
        border-color: #f5c2c7;
    }

    &-warning {
        color: #664d03;
        background-color: #fff3cd;
        border-color: #ffecb5;
    }

    &-dark {
        color: #141619;
        background-color: #d3d3d4;
        border-color: #bcbebf;
    }

    &-info {
        color: #055160;
        background-color: #cff4fc;
        border-color: #b6effb;
    }

}

</style>