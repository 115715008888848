<template>
<div class="default-layout">

    <AlertModal />
    <LoginModal />

    <div class="top-menu-list" id="top-list-menu" v-if="show_menu">

        <div class="top-menu-list__header">
            <div class="logo">
                <div class="logo-title" @click="goHome">Dark-Anime</div>
            </div>
            <div class="top-menu-list-close"  @click="hideMenu"><i class="las la-times"></i></div>
        </div>

        <TopMenu :currentRouteName="currentRouteName" :hideMenu="hideMenu" />

    </div>
    <div class="top-menu-list-blackout" id="top-menu-list-blackout"  v-if="show_menu"></div>

    <div class="top-menu" :style="{ backgroundImage: 'url(/img/top_header_danime-res.jpg)'}">
        <div class="logo">
            <div class="logo-title" @click="goHome">Dark-Anime</div>
        </div>
        <div class="">&nbsp;</div>
        <div class="top-menu__bars" @click="showMenu"><i class="las la-bars"></i></div>
    </div>

    <div class="main">
       <router-view />

    </div>

</div>

</template>

<script>

import { mapState, mapGetters } from 'vuex'
import router from '@/router/router'

import AlertModal from '@/components/blocks/AlertModal.vue'
import LoginModal from '@/components/blocks/LoginModal.vue'

import TopMenu from '@/components/blocks/TopMenu.vue'


export default {
    name: "DefaultLayout",
    components: {
        AlertModal,
        LoginModal,
        TopMenu
    },
    computed: {

        ...mapState({
            "currentProfile": state => state.app.currentProfile,
            "currentAddress": state => state.app.currentAddress,
        }),

        ...mapGetters({
        }),

        layout() {
            return this.$route.meta.layout || 'default-layout'
        }
    },
    watch: {
       '$route.path'() {
            // сохраняем текущий роут
            this.showLoginModal();
        }
    },
    methods: {
        exitProfile() {

            this.$store.dispatch('app/setProfile', {     
                profile: null,
                password: null
            });
        },

        showMenu() {
            this.show_menu = true;
        },
        hideMenu() {
            this.show_menu = false;
        },
        goHome()  {
            router.push("/");
        },

        showLoginModal() {
            this.currentRouteName = this.$route.name;
        }
    },
    data: () => ({
        show_menu: false,
        currentRouteName: "home"
    }),

    mounted() {

        this.showLoginModal();

    }
}
</script>

<style lang="less">
.default-layout {

    .main {
        padding-top: 10px;
        padding-left: 15px;
        padding-right: 15px;
        padding-bottom: 30px;
        max-width: 800px;
        margin: 0 auto;
    }
    

    .top-menu{

        display: flex;
        justify-content: space-between;
        max-width: 800px;
        min-height: 80px;
        margin: 0 auto;
        background-position: top center;
        border-bottom: 1px solid #303030;

        &__bars {
            padding-right: 30px;
            padding-top: 30px;
            cursor: pointer;

            i {
                font-size:28px;
            }
        }
    }

    .content {
        margin-top:0;
    }

    @media (min-width: 768px) {

        .content {
            margin-top:0;
        }  
    }



    .top-menu-list {

        position: fixed;
        width: 100%;
        right:0;
        top: 0;
        height:100%;
        z-index:1000;
        display: block;
        padding: 0;

        background-color: black;
        color:white;

        &__header {
            display: flex;
            justify-content: space-between;

        }

        &__body {
            padding-left:30px;
            padding-top: 20px;

        }
     }

    .top-menu-list-blackout {
        display: block;
    }


    @media (min-width: 768px) {
        .top-menu-list {

            position: fixed;
            width: 39%;
            max-width:500px;
            right:0;
            top: 0;
            height:100%;
            z-index:1000;
            display: block;

            background-color: black;
            color:white;

            border-left: 1px solid #303030;
        }

        .top-menu-list-blackout {

            position: fixed;
            width: 100%;
            left:0;
            height:100%;
            z-index:900;
            display: block;
            opacity: 0.7;

            background-color: black;
        }
    }

    .top-menu-list-item {
        width:100%;
        padding: 12px;
        text-align: left;

        i {
            margin-right: 10px;
        }

    }
    @media (min-width: 768px) {
        .top-menu-list-item {
            width:100%;
            padding: 12px;
            text-align: left;

        }
    }

    .top-menu-list-item a {
        font-size:20px;
        color: white;
        text-decoration: none;
    }

    .top-menu-list-item a:hover {
        text-decoration: underline;
        color: #efefef;
    }


    .top-menu-list-close {
        color:#efefef;
        cursor: pointer;

        padding: 0;
        text-align: right;
        font-weight: 100;
        margin-bottom:15px;
    }

    .top-menu-list-close i {
        margin-right:30px;
        margin-top:15px;
        font-size:28px;
    }


    @media (min-width: 768px) {
        .top-menu-list-close i {
            margin-right:25px;
            margin-top:25px;
            font-size:32px;
        }
    }


    .top-menu-list-button {
        width:100%;
        padding: 15px;
        margin: 0 auto;
        width:50%;
    }


}

</style>