import { createApp } from 'vue'
import VueCryptojs from 'vue-cryptojs'

import App from './App.vue'

import router from './router/router'
import store from './store/store'

import DefaultLayout from './layouts/DefaultLayout.vue'
import BrowserLayout from './layouts/BrowserLayout.vue'


createApp(App)
.use(VueCryptojs)

.use(router)
.use(store)

.component('default-layout', DefaultLayout)
.component('browser-layout', BrowserLayout)
.mount('#app')
