import getters from './getters';
import actions from './actions';
import mutations from './mutations';

const state = {
    web3: null,
    currentAddress: "",
    //proContractAddress: "0x6c9085eDdbdB9c9FDd3C4f7Ca6d3BC022bA01FF1"
    //proContractAddress: "0x02e141a526a06c0B1D63A0aa33D1919e25951F2a"
    //proContractAddress: "0x492bD2B6F665Ad448D65B71f51F2EeA1E4cebB7D"
   // proContractAddress: "0xf8765B38217902A6Ae9E25FbA00FB1F132309A2f" - proshop
    proContractAddress: "0xBb753B72DACB40FB6B30cabCeB080117e4063DB4",


    // модальное окно с сообщением
    is_alert: false,
    alert_type:  "danger",
    alert_message:  "",

    // окно авторизации в профиле
    is_login_modal: false,

    currentProfile: null,
    currentPass: null
};

export default {
    state,
    getters,
    actions,
    mutations
};

