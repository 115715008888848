
import { createStore } from 'vuex'
import modules from './modules'


// Create a new store instance.
const store = createStore({
  modules,
  strict: process.env.NODE_ENV !== 'production',
  state: {
    layout: 'default-layout'
  },
  mutations: {
    setLayout(state, payload) {
      state.layout = payload
    }
  },
  actions: {},
  getters: {
    layout(state) {
      return state.layout
    }
  }
})
  
  // Автоматически запустить действие `init` для каждого существующего модуля
  for (const moduleName of Object.keys(modules)) {
    if (modules[moduleName].actions.init) {
      store.dispatch(`${moduleName}/init`)
    }
  }


export default store