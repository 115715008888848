<template>
    <div class="browser-home">

        <Loader :loading="loading" />
        <Alert v-if="is_error" type="danger" message="Ошибка получения данных!"/>


        <h1>Децентрализованный браузер</h1>
        <p>Введите в строку блокчейн-браузера путь до файла, адрес кошелька или домен децентрализованного сайта </p>
   </div>
 </template>
 
 <script>

import { mapState, mapGetters } from 'vuex'

import router from '@/router/router'

import Loader from '@/components/blocks/Loader.vue'
import Alert from '@/components/blocks/Alert.vue'
 
 export default {
     name: 'BrowserHome',
     components: {
        Loader,
        Alert
    },
     props: {
     },
     computed: {
            ...mapState({
                "web3": state => state.app.web3,
                "currentAddress": state => state.app.currentAddress,
                "currentProfile": state => state.app.currentProfile,
                "currentPass": state => state.app.currentPass,
            }),
    
            ...mapGetters({
            })
     
     },
     methods: {



     },
     data: () => ({
        is_error: false
   
    }),

    mounted() {

        if(this.currentAddress == "") {
            router.push("/connect");
        }
        
    }
 }
 </script>
 
 <style scoped lang="less">
 .browser-home {
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    h1 {
        margin-top: 30px;
    }
 }
 </style>