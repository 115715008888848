
export default {

    setWeb3(context, payload) {
        context.commit("SET_WEB3", payload);
    },

    setAlert(context, payload) {
        context.commit("SET_ALERT", payload);
    },

    setProfile(context, payload) {
        context.commit("SET_PROFILE", payload);
    },

    setLoginModal(context, payload) {
        context.commit("SET_LOGIN_MODAL", payload);
    },
    
};