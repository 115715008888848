export default class Utils {

    static list_to_tree(list) {
        var map = {}, node, roots = [], i;
        
        for (i = 0; i < list.length; i += 1) {
          map[list[i].id] = i; // initialize the map
          list[i].children = []; // initialize the children
        }
        
        for (i = 0; i < list.length; i += 1) {
          node = list[i];
          if (node.parent !== 0) {
            // if you have dangling branches check that map[node.parentId] exists
            list[map[node.parent]].children.push(node);
          } else {
            roots.push(node);
          }
        }
        return roots;
    }

    static genPassword(passwordLength) {

        var chars = "0123456789abcdefghijklmnopqrstuvwxyz!@#$%^&*()ABCDEFGHIJKLMNOPQRSTUVWXYZ";
        var password = "";

        for (var i = 0; i <= passwordLength; i++) {
            var randomNumber = Math.floor(Math.random() * chars.length);
            password += chars.substring(randomNumber, randomNumber +1);
        }

        return password;
    }


    static nl2br( str ) {	// Inserts HTML line breaks before all newlines in a string
        // 
        // +   original by: Kevin van Zonneveld (http://kevin.vanzonneveld.net)

        return str.replace(/([^>])\n/g, '$1<br/>');
    }

    // удалить все html-теги
    static cutTags(str) {
        return str.replace(/<\/?[^>]+>/ig, "");
    }

    // замена plain-url на html-ссылку
    static linkify(inputText) { 
        return inputText.replace(/((http:|https:)[^\s]+[\w])/g, '<a href="$1" target="_blank">$1</a>');
    }

    // обрезать строку
    static kitcut( text, limit) {
        text = text.trim();

        if( text.length <= limit) return text;
        text = text.slice( 0, limit); // тупо отрезать по лимиту
        let lastSpace = text.lastIndexOf(" ");

        if( lastSpace > 0) { // нашлась граница слов, ещё укорачиваем
          text = text.substr(0, lastSpace);
        }
        return text + "...";
    }


    // функция для перевода байты в килобайты, мегабайты
    static formatBytes(bytes, decimals = 2) {
      if (bytes === 0) {
        return '0';
      } else {
        var k = 1024;
        var dm = decimals < 0 ? 0 : decimals;
        var sizes = ['байт', 'КБ', 'МБ', 'ГБ', 'ТБ'];
        var i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
      }
    }

}