<template>
    <div class="admin-products">
        <Loader :loading="loading" />

        <h1>Товары</h1>
         
        <div class="admin-products__content"> 

            <Alert v-if="is_error" type="danger" :message="error_message"/>

            <Transaction v-if="transactionId" :id="transactionId" :title="transactionIdTitle"/>

            <router-link to="/admin/new-product" class="pure-button pure-button-primary"><i class="las la-plus-circle"></i> Создать товар</router-link>

            <p>* цена в MATIC</p>
            
            <table class="pure-table" width="100%" v-if="products.length > 0">
                <thead>
                    <tr>
                        <th>Код</th>
                        <th width="50">Цена</th>
                        <th width="40">&nbsp;</th>
                        <th width="40">&nbsp;</th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="pure-table-odd"  v-for="item in products" :key="item.code">
                        <td>{{item.code}}</td>
                        <td>{{item.price}}</td>
                        <td>
                            <button class="button-xsmall pure-button pure-button-primary" @click="update(item.code)"><i class="las la-edit"></i></button>
                        </td>
                        <td>
                            <button class="button-error button-xsmall pure-button" @click="remove(item.code)"><i class="las la-trash"></i></button>
                        </td>
                    </tr>
                   
                </tbody>
            </table>
            <div v-else>
                Товаров нет.
            </div>

        </div>
    </div>
</template>
    
<script>
    
    import router from '@/router/router'
    
    import { mapState, mapGetters } from 'vuex'

    import ProShopABI from '@/abi/proshop.json'

    import Loader from '@/components/blocks/Loader.vue'
    import Alert from '@/components/blocks/Alert.vue'
    import Transaction from '@/components/blocks/Transaction.vue'
    
    
    export default {
        name: "AdminProducts",
        components: {
            Loader,
            Alert,
            Transaction
        },
        props: {
        },
        computed: {
    
            ...mapState({
                "web3": state => state.app.web3,
                "currentAddress": state => state.app.currentAddress,
                "proContractAddress": state => state.app.proContractAddress
            }),
    
            ...mapGetters({
            }),
         
        },
        methods: {

            // https://ethereum.org/en/developers/tutorials/calling-a-smart-contract-from-javascript/
            getProducts() {

                this.psToken = new this.web3.eth.Contract(ProShopABI, this.proContractAddress);

                this.loading = true;

                this.psToken.methods.getProducts().call().then(res => {

                    this.products = res.map(item => ({ 
                        code: item[0], 
                        price: this.web3.utils.fromWei(item[1], 'ether')

                    })).filter(item => item.code !== "" && item.price > 0);

                    console.log(res);

                }).catch(error => {

                    console.log(error);

                    this.is_error = true;
                    this.error_message = "Ошибка получения данных!";

                }).finally(() => {

                    console.log("Transaction final");

                    this.loading = false;

                });
    
            },

            update(code) {
                router.push("/admin/update-product/"+code);
            },



            remove(code) {
         
                this.psToken = new this.web3.eth.Contract(ProShopABI, this.proContractAddress);


                this.loading = true;
                this.psToken.methods.removeProduct(code).send({ 
                    from: this.currentAddress
                }).then(transaction => {

                    console.log(transaction);

                    this.transactionId = transaction.transactionHash;
                    this.transactionIdTitle = this.transactionId.slice(0, 5) + '...' + this.transactionId.slice(-5);

                    this.getProducts();

                }).catch(error => {

                    console.log(error);

                    this.is_error = true;
                    this.error_message = "Ошибка удаления данных!";

                }).finally(() => {

                    console.log("Transaction final");

                    this.loading = false;

                });
         

            }

           
    
        },
        data: () => ({
    
            is_error: false,
            error_message: "",
            loading: false,

            products: [],

            transactionId:"",
            transactionIdTitle: "",

            psToken:null
        }),
        mounted() {
    
            if(this.currentAddress == "") {
                router.push("/connect");
            } else {
    
                // товары
                this.getProducts();
    
            } // end if
    
        },
    
        beforeDestroy() {
    
        }
    }
</script>
    
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
    .admin-products {
        margin: 0;
        margin-top:10px;

        .button-error {
            background: rgb(202, 60, 60);
            color:white;
            /* this is a maroon */
        }

        &__content {
            background-color: #F2F2F2;
            border-radius:15px;
            padding:15px;
            margin-bottom:15px;
            margin-top:15px;
            height:90vh;

            line-height: 25px;
        }
 
    
        h1 {
            color: black;
            font-size:18px;
            text-align: center;
            margin-top:15px;
            margin-bottom:20px;
            font-weight: 400;
        }

        .pro {
            background-color: lightgrey;
            padding:3px;
            border: 1px solid grey;
            border-radius: 5px;
            color: black;
        }
    
    
        &__buttons {
            border: 0;
            margin-top:35px;
    
            a {
                margin-right:10px;
    
                @media (max-width: 470px) { 
                       width: 100%;
                       margin-right:0;
                       margin-bottom:10px;
                }
            }        
        }
    
    }
    
    
</style>
    