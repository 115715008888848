<template>
    <div class="proshop">
        <h1><span class="pro">PRO</span> &mdash; аккаунт</h1>

        <div class="proshop__content">
            <p><span class="pro">PRO</span> &mdash; аккаунт активируется один раз и продления не требует.<br />
            Стоимость активации <b>5 MATIC</b>.
            </p>
        
            <div v-if="isPro" class="b-alert b-alert-success">
                <span class="pro"><i class="las la-check"></i> PRO</span> &mdash; аккаунт активирован.
            </div>
            <div v-else class="no_pro">
                <div class="wallet__buttons">
                    <router-link to="/new-transaction" class="pure-button pure-button-primary">Купить PRO</router-link>
                </div>
            </div>

        </div>
         
    </div>
    </template>
    
    <script>
    
    //import axios from 'axios'
    import router from '@/router/router'
    
    import { mapState, mapGetters } from 'vuex'

    import ProShopABI from '@/abi/proshop.json'

    console.log(ProShopABI);
    
    
    export default {
        name: "ProShop",
        components: {
        },
        props: {
        },
        computed: {
    
            ...mapState({
                "web3": state => state.app.web3,
                "currentAddress": state => state.app.currentAddress,
            }),
    
            ...mapGetters({
            }),
         
        },
        methods: {

            // https://ethereum.org/en/developers/tutorials/calling-a-smart-contract-from-javascript/
    
            getPro() {

                this.psToken = new this.web3.eth.Contract(ProShopABI, this.PS_ADDRESS);

                this.psToken.methods.isPaid(this.currentAddress, 'pro').call((err, res) => {
                    if (err) {
                        console.log("An error occurred", err)
                        return
                    }

                    this.isPro = res;
                    console.log("isPaid is: ", res)

                })
    
            },

            paymentPro() {
/*
                this.psToken.methods
                .payProduct("pro")
                .send({ 
                    from: this.currentAddress, 
                    gasPrice: "20000000000", 
                    gas: "21000", 
                    to: this.PS_ADDRESS, 
                    value: this.web3.utils.toWei('5000000000000000000', 'ether'), 
                    data: "" 
                 }, function (err, res) {
                    if (err) {
                    console.log("An error occurred", err)
                    return
                    }
                    console.log("Hash of the transaction: " + res)
                })
                */


            }
    
        },
        data: () => ({
    
            name: "",
            surname: "",
            patronymic: "",
            isPro: false,

            psToken:null,
            PS_ADDRESS: '0x42537D246d1B2712A825554BfA12dda95ABb548f'
        }),
        mounted() {
    
            if(this.currentAddress == "") {
                router.push("/connect");
            } else {
    
                // узнать есть ли у пользователя pro-аккаунт
                this.getPro();
    
            } // end if
    
        },
    
        beforeDestroy() {
    
        }
    }
    </script>
    
    <!-- Add "scoped" attribute to limit CSS to this component only -->
    <style scoped lang="less">
    .proshop {
        margin: 0;
        margin-top:10px;

        &__content {
            background-color: #F2F2F2;
            border-radius:15px;
            padding:15px;
            margin-bottom:15px;
            margin-top:15px;
            height:90vh;

            line-height: 25px;
        }
 
    
        h1 {
            color: black;
            font-size:18px;
            text-align: center;
            margin-top:15px;
            margin-bottom:20px;
            font-weight: 400;
        }

        .pro {
            background-color: lightgrey;
            padding:3px;
            border: 1px solid grey;
            border-radius: 5px;
            color: black;
        }
    
    
        &__buttons {
            border: 0;
            margin-top:35px;
    
            a {
                margin-right:10px;
    
                @media (max-width: 470px) { 
                       width: 100%;
                       margin-right:0;
                       margin-bottom:10px;
                }
            }        
        }
    
    }
    
    
</style>
    