<template>
    <div class="admin">
        <div class="header">
            <h1>Панель управления</h1>
        </div>

        <div class="content admin__content greybg">

            <h2>Каури / ICO</h2>

            <div class="admin__content-link">
                <router-link to="/admin/cowry"><i class="las la-arrow-right"></i> Сводная информация</router-link>
            </div>

            <div class="admin__content-link">
                <router-link to="/admin/products"><i class="las la-arrow-right"></i> Перевести со смарт-контракта</router-link>
            </div>

            <div class="admin__content-link">
                <router-link to="/admin/products"><i class="las la-arrow-right"></i> ICO / Вывод средств</router-link>
            </div>

            <h2>ProShop</h2>

            <div class="admin__content-link">
                <router-link to="/admin/new-product"><i class="las la-arrow-right"></i> Создать/Обновить товар</router-link>
            </div>
            <div class="admin__content-link">
                <router-link to="/admin/products"><i class="las la-arrow-right"></i> Товары</router-link>
            </div>

            <div class="admin__content-link">
                <router-link to="/admin/products"><i class="las la-arrow-right"></i> Баланс смарт-котракта</router-link>
            </div>

            <div class="admin__content-link">
                <router-link to="/admin/products"><i class="las la-arrow-right"></i> Вывод средств</router-link>
            </div>


        </div>
         
    </div>
    </template>
    
    <script>
    
    //import axios from 'axios'
    import router from '@/router/router'
    
    import { mapState, mapGetters } from 'vuex'
    
    
    export default {
        name: "Admin",
        components: {
        },
        props: {
        },
        computed: {
    
            ...mapState({
                "web3": state => state.app.web3,
                "currentAddress": state => state.app.currentAddress,
                "proContractAddress": state => state.app.proContractAddress
            }),
    
            ...mapGetters({
            }),
         
        },
        methods: {
    
            getBalance() {
    
                this.web3.eth.getBalance(this.currentAddress).then(wei => {
    
        
                    let balanceMax =  this.web3.utils.fromWei(wei, 'ether');
                    let bArr = balanceMax.split('.');
                    console.log(bArr);
    
                    this.balance =  bArr[0] + '.' + bArr[1].substr(0, 4);
    
                    console.log(this.balance);
                 
                });
            },
    
    
            copy() {
    
                    const el = document.createElement('textarea');
                    el.value = this.currentAddress;
                    el.setAttribute('readonly', '');
                    el.style.position = 'absolute';
                    el.style.left = '-9999px';
                    document.body.appendChild(el);
                    el.select();
                    document.execCommand('copy');
                    document.body.removeChild(el);
    
                    alert("Адрес скопирован в буфер обмена!");
            }
    
        },
        data: () => ({
    
            name: "",
            surname: "",
            patronymic: "",
            balance: 0
        }),
        mounted() {

            
        console.log("admin this.currentAddress: "+this.currentAddress);
 
    
            if(this.currentAddress == "") {
                router.push("/connect");
            } else {
    
                // получить данные пользователя
                this.getBalance();
    
            } // end if
    
        },
    
        beforeDestroy() {
    
        }
    }
    </script>
    
    <!-- Add "scoped" attribute to limit CSS to this component only -->
    <style scoped lang="less">
    .admin {
        margin: 0;
        margin-top:10px;
        display: flex;
        flex-direction: column;

        &__content {
            border-radius:15px 15px 0 0;
            padding:10px 15px 15px 15px;
            flex-basis: 95%;
            

            h2 {
                font-size:18px;
                margin-top:30px;
            }

            &-link {
                margin-top:20px;
                a {
                    font-size:16px;
                    text-decoration: none;
                    color: black;
                    padding: 20px;
                }
            }
        }
    
    }
    
    
    </style>
    