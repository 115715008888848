<template>
    <div class="update-profile">
        <Loader :loading="loading" />

        <Transaction :id="transactionId"/>

        <h1>Изменить ключ</h1>

        <form class="pure-form pure-form-stacked">
            <fieldset>

                <label for="stacked-folder">Папка</label>
                   <select name="folder" id="stacked-folder" v-html="htmlFolderOptions" @change="changeFolder">
                </select>

     
                
                <label for="stacked-type">Тип *</label>
                <select name="type" id="stacked-type"  @change="changeType">
                    <option value="s" :selected="def_type == 's'">Симметричный ключ</option>
                    <option value="a" :selected="def_type == 'a'">Асимметричный ключ (пара)</option>
                    <option value="l" :selected="def_type == 'l'">Логин/пароль</option>
                    <option value="n" :selected="def_type == 'n'">Заметка</option>
                </select>

                <div v-if="def_type == 'a' || def_type == 's'">
                    <label for="stacked-name">Алгоритм шифрования *</label>
                    <select name="algo" @change="changeAlgo">
                        <option value="rsa" v-if="def_type=='a'" :selected="def_algo == 'rsa'">RSA</option>
                        <option value="aes" v-if="def_type=='s'" :selected="def_algo == 'aes'">AES</option>
                    </select>
                </div>


                <label for="stacked-name">Название</label>
                <input 
                        type="text" 
                        id="stacked-name" 
                        placeholder="Любые символы..." 
                        :value="def_name"
                        @input="event => name = event.target.value"
                />

                <div>
                    <label for="stacked-login">Логин</label>
                    <input 
                            type="text" 
                            id="stacked-login" 
                            placeholder="Латинские символы, цифры, _" 
                            :value="def_login"
                            @input="event => login = event.target.value"
                    />
                </div>


                <div v-if="def_type == 's' || def_type == 'l'">
                    <label for="stacked-password">{{def_type == 's'?'Ключ':'Пароль'}}</label>
                    <input 
                            type="text" 
                            id="stacked-password" 
                            placeholder="Сгененрирйте или придумайте ключ" 
                            :value="def_password"
                            @input="event => password = event.target.value"
                    />
                    <input type="text" value="s_sizegen" placeholder="Длинна ключа" v-model="s_sizegen"/><button @click="s_gen">Сгенерировать</button>
                    <span class="pure-form-message"><b>Внимание!</b> Длинна ключа не должна быть короткой, иначе сообщения можно будет расшифровать прямым перебором!</span>
                </div>

                <div v-if="def_algo == 'rsa' && def_type == 'a'">
                    <label for="stacked-pubkey">Публичный ключ</label>
                    <textarea 
                            id="stacked-pubkey" 
                            placeholder="Сгененрирйте публичный ключ" 
                            v-model="def_publicKey"
                            readonly
                    ></textarea>

                    <label for="stacked-privkey">Приватный ключ</label>
                    <textarea 
                            id="stacked-privkey" 
                            placeholder="Сгененрирйте приватный ключ" 
                            v-model="def_privateKey"
                            readonly
                    ></textarea>

                    <input type="text" value="a_sizegen" placeholder="Длинна ключа" v-model="a_sizegen"/><button @click="a_gen">Сгенерировать</button>
                </div>

                <label for="stacked-text">Комментарий</label>
                <textarea 
                        placeholder="Любые символы..." 
                        id="stacked-text"
                        :value="def_text"
                        @input="event => text = event.target.value"
                ></textarea>

                <span class="pure-form-message">Комментарий сможет прочитать, только тот, кто знает мастер-пароль.</span>

                <button type="submit" class="pure-button pure-button-primary"  @click="updateKey">Сохранить</button>
            </fieldset>
        </form>


        <div class="back">
            <div @click="back"><i class="las la-arrow-left"></i>&nbsp; Назад</div>
        </div>
    
    </div>
</template>
<script>
    
import router from '@/router/router'


import IDB from '@/utils/idb'

import Loader from '@/components/blocks/Loader.vue'

import Utils from '@/utils/utils'

    
import { mapState, mapGetters } from 'vuex'

var keypair = require('keypair');
    
  //  import locales from '@/utils/locales';
    
export default {
        name: "CreateKey",
        components: {
            Loader
        },
        props: {
            id: String
        },
        computed: {
            ...mapState({
                "web3": state => state.app.web3,
                "currentAddress": state => state.app.currentAddress,
                "currentProfile": state => state.app.currentProfile,
                "currentPass": state => state.app.currentPass,
            }),
    
            ...mapGetters({
            }),

            folders() {
                let folders = this.currentProfile.data.folders;

                return folders;

               // return Utils.list_to_tree(folders);

            },

            htmlFolderOptions() {

                let res = '<option value="0">- Без папки -</option>';

                for(let item of this.folders) {

                    let selected = item.id == this.def_folder?"selected":"";

                    res += '<option value="'+item.id+'" key="f'+item.id+(new Date().getTime())+'" '+selected+'>'+item.name+'</option>';

                    /*
                    if(typeof item.children !== "undefined" && item.children.length > 0) {
                        for(let item2 of item.children) {
                            res += '<option value="'+item2.id+'" key="f'+item2.id+(new Date().getTime())+'"> - '+item2.name+'</option>';
                        
                            if(typeof item2.children !== "undefined" && item2.children.length > 0) {
                                for(let item3 of item2.children) {
                                    res += '<option value="'+item3.id+'" key="f'+item3.id+(new Date().getTime())+'"> - '+item3.name+'</option>';
                                }
                            }
                        
                        }
                    }
                    */
                }

                return res;

            },

            def_folder() {
                if(this.folder == 0) {
                    let keys = this.currentProfile.data.keys;

                    return keys[this.id].folder;

                }

                return this.folder;
            },

            def_type() {
                if(this.type.trim() == "") {
                    let keys = this.currentProfile.data.keys;

                    return keys[this.id].type;

                }

                return this.type;
            },

            def_algo() {
                if(this.algo.trim() == "") {
                    let keys = this.currentProfile.data.keys;

                    return keys[this.id].algo;

                }

                return this.algo;
            },

            def_name() {
                if(this.name.trim() == "") {
                    let keys = this.currentProfile.data.keys;

                    return keys[this.id].name;

                }

                return this.name;
            },

            def_login() {
                if(this.login.trim() == "") {

                    return this.id;

                }

                return this.login; 
            },

            def_password() {
                if(this.password.trim() == "") {
                    let keys = this.currentProfile.data.keys;

                    return keys[this.id].password;

                }

                return this.password;
            },

            def_text() {
                if(this.text.trim() == "") {
                    let keys = this.currentProfile.data.keys;

                    return keys[this.id].text;

                }

                return this.text;  
            },

            def_publicKey() {
                if(this.publicKey.trim() == "") {
                    let keys = this.currentProfile.data.keys;

                    return keys[this.id].publicKey;

                }

                return this.publicKey;   
            },

            def_privateKey() {
                if(this.privateKey.trim() == "") {
                    let keys = this.currentProfile.data.keys;

                    return keys[this.id].privateKey;

                }

                return this.privateKey;      
            },  
            
        },
        methods: {
    
            back() {
                router.push("/profile");
            },


            changeFolder(e) {
                this.folder = e.target.value;

            },

            changeType(e) {
                this.type = e.target.value;

                if(this.type == "s") this.algo = "aes";
                if(this.type == "a") this.algo = "rsa";
                if(this.type == "l" || this.type == "l") this.algo = "";
            },

            changeAlgo(e) {
                this.algo = e.target.value;
            },

            s_gen(event) {
                event.preventDefault();

                this.password = Utils.genPassword(this.s_sizegen);
            },

            a_gen(event) {
                event.preventDefault();


                let pair = keypair({bits: this.a_sizegen});
                this.publicKey = pair.public.replace('-----BEGIN RSA PUBLIC KEY-----\n','')
                              .replace('\n-----END RSA PUBLIC KEY-----\n','').trim();

                this.privateKey = pair.private.replace('-----BEGIN RSA PRIVATE KEY-----\n','')
                              .replace('\n-----END RSA PRIVATE KEY-----\n','').trim();  

            },
    
            updateKey(event) {
                event.preventDefault();


                if(this.currentProfile == null) {

                    this.$store.dispatch('app/setLoginModal', { 
                       show:  true
                    });

                } else if(this.def_login === "") {

                    this.$store.dispatch('app/setAlert', { 
                        is_alert:  true,
                        type:  "danger",
                        message: "Логин должен быть заполнен!",
                    });
                
                } else {


                    let profile = this.currentProfile;
                    let profileId = profile.id;
                    let keys = profile.data.keys;

                    let { def_folder, def_type, def_name, def_algo, def_login, def_password, def_publicKey, def_privateKey,  def_text } = this;

                    let keydata = { 
                        folder: Number(def_folder), 
                        type: def_type, 
                        name: def_name, 
                        algo: def_algo, 
                        login: def_login, 
                        password: def_password, 
                        publicKey: def_publicKey, 
                        privateKey: def_privateKey, 
                        text: def_text 
                    };

                    keys[def_login] = keydata;

                    let data = { 
                            text: profile.text, 
                            keys, 
                            folders: profile.data.folders
                    };

                    let jsonData = JSON.stringify(data);
                    const encryptedText = this.$CryptoJS.AES.encrypt(jsonData, this.currentPass).toString();

                    let saveData = { name: profile.name, login: profile.login, data: encryptedText  }; 

                    // ------------- Запись данных
                    IDB.saveProfile(profileId, saveData).then(() => {

                        let myprofile = {
                            id: profileId,
                            name: profile.name,
                            login: profile.login,
                            data
                        };

                        this.$store.dispatch('app/setProfile', { 
                            profile: myprofile,
                            password: this.currentPass,
                        });

                        router.push("/profile");

                        this.$store.dispatch('app/setAlert', { 
                            is_alert:  true,
                            type:  "success",
                            message: "Данные сохранены!",
                        });
                           

                    }).catch(() => {

                        this.$store.dispatch('app/setAlert', { 
                            is_alert:  true,
                            type:  "danger",
                            message: "Ошибка сохранения данных!",
                        });
                            
                    });


                } // end if
         

                
        
            }
            
    
        },
        data: () => ({

 
            folder: 0,
            type: "",
            algo: "",

            name: "",
            login: "",
            password: "",
            text: "",

            s_sizegen: 32,
            a_sizegen: 1024,

            publicKey: "",
            privateKey: "",

            loading: false,
    
            is_success: false,
            is_error: false
    
        }),
        mounted() {
    
    
            if(this.currentAddress == "") {
               // router.push("/connect");
            } // end if

            if(this.currentProfile === null) {
                
                this.$store.dispatch('app/setLoginModal', { 
                    show:  true
                });
            }
    
        }
}
</script>
    
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
    
.update-profile {
    margin: 0;
    margin-top:10px;
    display: flex;
    flex-direction: column;

    &__header {
        padding-top: 5px;
        padding-bottom: 15px;
    }

    &__content {
        border-radius:15px 15px 0 0;
        padding:10px 15px 15px 15px;
            

        h2 {
            font-size:18px;
            margin-top:30px;
        }
    }
    

        h3 {
            font-size: 16px;
            margin-top:20px;
        }
    
        textarea {
            width:100%;
            padding:10px;
            border: 1px solid silver;
            margin-top:5px;
            height: 150px;
        }
    
        input {
            width:62%;
            padding:10px;
            border: 1px solid silver;
            margin-top:5px;
        }
    
        input[type=button] {
            padding: 5px 15px;
            margin-top:15px;
            width:auto;
            cursor: pointer;
        }
    
        input[type=checkbox] {
            width: 20px !important; 
        }
    
        select {
            width:62%;
            padding: 5px 15px;
            margin-top:5px;
            cursor: pointer;
        }

        label {
            padding:5px;
            margin-top:10px;
        }

    
        @media (max-width: 768px) {
            select {
                width:100%;
            }
    
            input {
                width:100%;
            }
        }
        
    }
    
    </style>