<template>
    <div class="b-alert b-alert-success" v-if="id">
        Транзакция успешно отправлена!<br />
        О ходе выполненения транзакции можно узнать на сайте 
        <a :href="'https://polygonscan.com/tx/'+transactionId" target="_blank">polygonscan.com</a><br />
        ID транзакции: <b>{{transactionIdTitle}}  &nbsp;<i class="las la-copy" @click="() => copy()" title="Скопировать в буфер обмена"></i></b>

    </div>
</template>

<script>
export default {
    name: 'Transaction',
    props: {
      id: String
    },
    computed: {
        transactionIdTitle() {
            return this.id.slice(0, 5) + '...' + this.id.slice(-5);
        }
    },   
    methods: {
        copy() {

            if(this.currentAddress) {

                const el = document.createElement('textarea');
                el.value = this.currentAddress;
                el.setAttribute('readonly', '');
                el.style.position = 'absolute';
                el.style.left = '-9999px';
                document.body.appendChild(el);
                el.select();
                document.execCommand('copy');
                document.body.removeChild(el);

                alert("Хеш скопирован в буфер обмена!");
            }
        }
    },     
    data: () => ({
    }),
    mounted() {

        this.transactionId = this.id;

    }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
</style>