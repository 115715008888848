<template>
    <div class="scan-qr">
    
        <h1>Сканировать QR-код</h1>

        <div v-if="error !== ''" class="alert-danger">
            {{ error }}
        </div>

        <p class="decode-result">Адрес кошелька: <b>{{ address }}</b></p>

        <qrcode-stream @decode="onDecode" @init="onInit" />

        <div class="scan-qr__button pure-button pure-button-primary" @click="back">Вернуться</div>

    </div>
</template>
 <script>
    
    import router from '@/router/router'
    
    //import axios from 'axios'

    import { QrcodeStream } from 'vue-qrcode-reader'

    //import FCLI from '@/utils/fcli'
    
    import { mapState, mapGetters } from 'vuex'
    
    export default {
        name: "ScanQR",
        components: {
            QrcodeStream
        },
        props: {
            addr: String
        },
        computed: {
            ...mapState({
                "user": state => state.app.user,
            }),
    
            ...mapGetters({
               // "currentServer": "app/currentServer",
              //  "user_id": "app/user_id",
               // "session_id": "app/session_id",
               // "temp_session_id": "app/temp_session_id"
            }),

            address() {
                return this.result.replace('https://nftpl.net/new-transaction/', '').trim();

            }


     
        },
        methods: {
    
            onDecode (result) {
                this.result = result
            },

            async onInit (promise) {
                try {
                    await promise
                } catch (error) {
                    if (error.name === 'NotAllowedError') {
                    this.error = "ERROR: you need to grant camera access permission"
                    } else if (error.name === 'NotFoundError') {
                    this.error = "ERROR: no camera on this device"
                    } else if (error.name === 'NotSupportedError') {
                    this.error = "ERROR: secure context required (HTTPS, localhost)"
                    } else if (error.name === 'NotReadableError') {
                    this.error = "ERROR: is the camera already in use?"
                    } else if (error.name === 'OverconstrainedError') {
                    this.error = "ERROR: installed cameras are not suitable"
                    } else if (error.name === 'StreamApiNotSupportedError') {
                    this.error = "ERROR: Stream API is not supported in this browser"
                    } else if (error.name === 'InsecureContextError') {
                    this.error = 'ERROR: Camera access is only permitted in secure context. Use HTTPS or localhost rather than HTTP.';
                    } else {
                    this.error = `ERROR: Camera error (${error.name})`;
                    }
                }
            },

            back() {
                router.go(-1);
            }

        },

        watch:{
            address(){
                if(this.address !=='') {
                    router.push('/new-transaction/'+this.address);
                }
            }
        },
        data: () => ({
            result: '',
            error: ''
        }),
        mounted() {

            // если не привязал кошелёк перебрасываем
            if(this.user && this.user.addr) {

                // адрес из url
                if(typeof this.addr !== "undefined") {
                    this.address = this.addr;
                } // end if

            } else {
               router.redirect("/profile");
                
            } // end if
    
        }
    }
    </script>
    
    <!-- Add "scoped" attribute to limit CSS to this component only -->
    <style scoped lang="less">
    .scan-qr {
        padding:15px;

        .error {
            color: red;
            padding: 10px;
        }

        .decode-result {
            padding: 10px;
        }

        &__button {
           width: 100%;
           margin-top:10px;
        }

        
    }
    
    </style>