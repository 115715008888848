<template>
    <div class="create-file">
     <Loader :loading="loading" />

    <Transaction :id="transactionId"/>

            <h1><i class="las la-cloud-upload-alt"></i> Добавить файл</h1>

            <form class="pure-form pure-form-stacked">
                <fieldset>

                    <label for="stacked-title">Заголовок</label>
                    <input 
                        type="text" 
                        id="stacked-title" 
                        placeholder="Заголовок" 
                        :value="file_title"
                        @input="event => file_title = event.target.value"
                    />


                    <div class="create-file__inp">
                        <input type="file" @change="readFile" />

                        <div v-if="file_name !== ''">
                            Имя: <b>{{file_name}}</b> <br />
                            Размер:  <b>{{formatBytes(file_size)}}</b> <br />
                            Тип:  <b>{{file_type}}</b>
                        </div>

                        <div class="create-file__inp-image" @click="fileImageMin" v-if="file_is_image">
                            <img :src="file_content"  
                                 :class="file_is_image_min?'create-file__inp-image-min':'create-file__inp-image-max'"
                            />
                        </div>

                        <div class="create-file__inp-link" v-if="file_name !== ''">
                            <i class="las la-file-download"></i> <a :download="file_name" :href="file_content">Скачать</a>
                        </div>
                    </div>



                    <div v-if="!no_encrypt">
                        <label for="stacked-key">Ключ шифрования</label>
                        <select name="folder" id="stacked-key" v-html="htmlOptions" @change="changeKey">
                        </select>

                        <br />
                        <router-link to="/create-profile-key" class="pure-form-message">
                            Добавить ключ шифрования
                        </router-link>
                    </div>


            

                    <label for="stacked-remember" class="pure-checkbox">
                        <input type="checkbox" id="stacked-remember" v-model="no_encrypt" /> * Не шифровать файл
                    </label>

                    <button type="submit" class="pure-button pure-button-primary"  @click="createFile">Создать</button>
                </fieldset>
            </form>

            <p>
                * Файл не будет зашифрован, и его содержимое будет доступно всем по ссылке (без пароля)
            </p>

            <div class="back">
                <div @click="back"><i class="las la-arrow-left"></i>&nbsp; Назад</div>
            </div>

    
    </div>
    </template>
    <script>
    
    import router from '@/router/router'
    //import axios from 'axios'
    //import DateTime from '@/utils/datetime'

    import Utils from '@/utils/utils'
    
    import Loader from '@/components/blocks/Loader.vue'
    import Transaction from '@/components/blocks/Transaction.vue'

    import CloudABI from '@/abi/cloud.json'
    
    import { mapState, mapGetters } from 'vuex'
    
  //  import locales from '@/utils/locales';
    
    export default {
        name: "CloudAddFile",
        components: {
            Loader,
            Transaction
        },
        props: {
        },
        computed: {
            ...mapState({
                "web3": state => state.app.web3,
                "currentAddress": state => state.app.currentAddress,
                "currentProfile": state => state.app.currentProfile,
                "currentPass": state => state.app.currentPass,
            }),
    
            ...mapGetters({
            }),

            folders() {
                let folders = this.currentProfile.data.folders;

                let keys = this.currentProfile.data.keys;


                for (let i in folders) {

                    folders[i]["keylist"] = [];

                    for (let key in keys) {
                        if(keys[key].folder == folders[i].id) {
                            folders[i]["keylist"].push(keys[key]);
                        }
                    }
                }

                return folders;

            },

            defKeyId() {

                if(this.keyId == "") {

                    let keys = this.currentProfile.data.keys;

                    let k = "";
                    for (let key in keys) {
                        if(keys[key].type == 's') {
                            k = key;
                            break;
                        }
                    }

                    return k;

                }

                return this.keyId;

            },

            htmlOptions() {
                
                let res = '<option value="0" disabled>- Без папки -</option>';

                for(let item of this.folders) {

                    res += '<option value="'+item.id+'" key="f'+item.id+(new Date().getTime())+'" disabled>'+item.name+'</option>';

                    for (let key in item.keylist) {
                        // только симетричные ключи
                        if(item.keylist[key].folder == item.id  && item.keylist[key].type == 's') {

                            let selected = key == this.defKeyId?"selected":"";

                            res += '<option value="'+item.keylist[key].login+'" key="k'+item.keylist[key].login+(new Date().getTime())+'" '+selected+'> - '+item.keylist[key].name+'</option>';

                        }
                    }
                }

                return res;

            },

            file_is_image() {
               return this.file_type == "image/jpeg" || this.file_type == "image/png" || this.file_type == "image/gif";
            },
            
            resultJson(){

                let keys = this.currentProfile.data.keys;


                let fileObj = {
                        title: this.file_title,
                        name: this.file_name,
                        size: this.file_size,
                        type: this.file_type,
                        content: this.file_content
                };

                let jsonData = JSON.stringify(fileObj);


                if(this.no_encrypt) {
                    return jsonData;

                } else if(!this.no_encrypt && this.defKeyId !== "" && typeof keys[this.defKeyId] !== "undefined") {


                    let password = keys[this.defKeyId].password;
                    let type = keys[this.defKeyId].type;
                    let algo = keys[this.defKeyId].algo;

                    if(type == "s" && algo == "aes") {

                        const encryptedText = this.$CryptoJS.AES.encrypt(jsonData, password).toString();

                        console.log("s:aes:"+this.defKeyId+";"+encryptedText);

                        return "s:aes:"+this.defKeyId+";"+encryptedText;

                    }
                }

                return "err";
                
            }             
        },
        methods: {
    
            back() {
                router.push("/cloud");
            },

            
            changeKey(e) {
                this.keyId = e.target.value;

            },

            fileImageMin() {
                this.file_is_image_min = !this.file_is_image_min;
            },

            formatBytes(size) {
                return Utils.formatBytes(size);
            },

            readFile(event) {
                let file = event.target.files[0];
                this.file = file;

                console.log(file);

                
                this.file_name = file.name;
                this.file_size = file.size;
                this.file_type = file.type;
                this.file_content = "";


                let reader = new FileReader();

                reader.readAsDataURL(file);

                reader.onload = () => {

                    this.file_content = reader.result;

                    console.log(this.file_content);
                };

                reader.onerror = function() {
                   console.log(reader.error);
                };

            },

        
    
            createFile(event) {
                event.preventDefault();

                this.loading = true;

                this.contractToken = new this.web3.eth.Contract(CloudABI, this.cloudAddress, { from: this.currentAddress });

                this.contractToken.methods.create(this.resultJson, this.defKeyId).send().then((transaction) => {

                    this.transactionId = transaction.transactionHash;

                    this.preview = "";
                    this.text = "";
                    this.keyId = "";

                }).catch(() => {

                    this.$store.dispatch('app/setAlert', { 
                        is_alert:  true,
                        type:  "danger",
                        message: "Ошибка отправки транзакции!",
                    });
                        
                }).finally(() => {

                    this.loading = false;
                });
              
    
            }
    
        },
        data: () => ({

            no_encrypt: false,

            file_title:"",
            file_name: "",
            file_size: "",
            file_type: "",
            file_content: "",
            file_is_image_min: true,

            
            contractToken: "",

            preview: "",
            text: "",
            keyId: "",

            // cloudAddress: "0x31f34d7E4D475469A58c91C54fb42E5fbEba78C5",
            cloudAddress: "0xf3DB4c7a06a2154EbE1CD88024EF3641CB9b24e5",


            transactionId: "",

            loading: false,
    
            is_success: false
    
        }),
        mounted() {
    
    
            if(this.currentAddress == "") {
                router.push("/connect");
            } // end if

            if(this.currentProfile === null) {
                
                this.$store.dispatch('app/setLoginModal', { 
                    show:  true
                });
            } 
    
        }
    }
    </script>
    
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
    
.create-file {
    margin: 0 auto;
    margin-top:10px;
    display: flex;
    flex-direction: column;
    max-width: 500px;

        &__inp {

            border: 1px solid silver;
            padding: 10px;
            margin-top:10px;

            &-link {
                margin-top: 20px;
                margin-bottom: 10px;

                a {
                    color: black;
                    text-decoration: none;
                }

                i {
                    font-size:20px;
                }
            }

            input[type=file] {
                width:100%;
                padding:0;
                border: 0;
                margin-top:5px;
                margin-bottom:5px;
            }

            &-image {
                margin-top:10px;
                cursor: pointer;

                &-min {
                    max-width: 150px;
                }

                &-max {
                    width: 95%;
                    max-width:none;
                }
            }

        }
    
        input[type=button] {
            padding: 5px 15px;
            margin-top:15px;
            width:auto;
            cursor: pointer;
        }
    
        input[type=checkbox] {
            width: 20px !important; 
        }
    
        select {
            width:62%;
            padding: 5px 15px;
            margin-top:5px;
            cursor: pointer;
        }

        label {
            padding:5px;
            margin-top:10px;
        }

    
        @media (max-width: 768px) {
            select {
                width:100%;
            }
    
            input {
                width:100%;
            }
        }
        
    }
    
    </style>