<template>
    <div class="lds-hourglass" v-if="loading"></div>
</template>

<script>
export default {
    name: 'Loader',
    props: {
      loading: Boolean
    },
    computed: {
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.lds-hourglass {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;

  position: fixed;
  left: 50%;
  top: 50%;
  margin-left:-50px;
  opacity: 0.85;
  display: inline-block;
  z-index:1000;
}
.lds-hourglass:after {
  content: " ";
  display: block;
  border-radius: 50%;
  width: 0;
  height: 0;
  margin: 8px;
  box-sizing: border-box;
  border: 32px solid #ffd900;
  border-color: #ffd900 transparent #ffd900 transparent;
  animation: lds-hourglass 1.2s infinite;
}
@keyframes lds-hourglass {
  0% {
    transform: rotate(0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  50% {
    transform: rotate(900deg);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  100% {
    transform: rotate(1800deg);
  }
}
</style>