export default class IDB {

    static indexedDBSupport(){
        return 'indexedDB' in window;
    }

    static createDatabase(callback) {

        if (!IDB.indexedDBSupport()) throw new Error("Your browser doesn't support IndexedBD");
    
        const dbPromise = window.indexedDB.open('w3un', 1);
    
        // Event handling
        dbPromise.onerror = () => {
            console.error(`IndexedDB error: ${dbPromise.errorCode}`);
        };
    
        dbPromise.onsuccess = () => {
            console.info('Successful database connection');
            const db = dbPromise.result;

           callback(db);
    
        };
    
        dbPromise.onupgradeneeded = (e) => {
            console.info('Database created');
            const db = dbPromise.result;

            switch(e.oldVersion) { // существующая (старая) версия базы данных
                case 0:
                    // версия 0 означает, что на клиенте нет базы данных
                    // выполнить инициализацию

                    // если хранилище не существует
                    if (!db.objectStoreNames.contains('profiles')) { 

                        var objectStore = db.createObjectStore("profiles", { autoIncrement: true, keyPath: 'id' });

                        // Create an index to search customers by name. We may have duplicates
                        // so we can't use a unique index.
                        objectStore.createIndex("name_index", "name", { unique: false });
            
                        // Create an index to search customers by email. We want to ensure that
                        // no two customers have the same email, so use a unique index.
                        objectStore.createIndex("login_index", "login", { unique: true });
                    } // end if

                break;
                case 1:
                    // обновить
                    // на клиенте версия базы данных 1
                    console.log("onupgradeneeded");
                 
                break;
            }
        };
    
    }

    static addProfile(profile) {

        // Создаётся объект promise
        let promise = new Promise((resolve, reject) => {

            IDB.createDatabase((db) => {
                let transaction = db.transaction("profiles", "readwrite"); // (1)
                var profiles = transaction.objectStore("profiles");

                let request = profiles.add(profile); // (3)

                request.onsuccess = function() { // (4)
                    resolve(request.result);
                };
                
                request.onerror = function() {
                    reject(request.error);
                };
    
            });
        
        });


        return promise;


    }

    static removeProfile(id) {

        // Создаётся объект promise
        let promise = new Promise((resolve, reject) => {

            IDB.createDatabase((db) => {
                let transaction = db.transaction("profiles", "readwrite"); // (1)
                var profiles = transaction.objectStore("profiles");

                let request = profiles.delete(id); // (3)

                request.onsuccess = function() { // (4)
                    resolve(request.result);
                };
                
                request.onerror = function() {
                    reject(request.error);
                };
    
            });
        
        });


        return promise;


    }

    static profiles() {
        // Создаётся объект promise
        let promise = new Promise((resolve, reject) => {

            IDB.createDatabase((db) => {
                var list = [];
                var item;
                var transaction = db.transaction("profiles", "readonly");
                var store = transaction.objectStore("profiles");
                var index = store.index("login_index");
                var cursor = index.openCursor();
    
                cursor.onsuccess = function (event) {
                    item = event.target.result;
                    if (item != null) {
                        list.push(item.value)
                        item.continue();
                    }
                    else {
                        resolve(JSON.parse(JSON.stringify(list)));
                    }
                };

                cursor.onerror = function() {
                    reject(cursor.error);
                };
    
            });
        
        });


        return promise;  
    }

    static getProfile(id) {

        // Создаётся объект promise
        let promise = new Promise((resolve, reject) => {

            IDB.createDatabase((db) => {
                var transaction = db.transaction("profiles", "readonly");
                var store = transaction.objectStore("profiles");
                var getRequest = store.get(id);
    
                getRequest.onsuccess = function (event) {
                    var item = event.target.result;
                    if (item !== undefined) {
                        resolve(JSON.parse(JSON.stringify(item)));
                    } else {
                        reject();
                    }
                };

                getRequest.onerror = function() {
                    reject(getRequest.error);
                };
    
            });
        
        });


        return promise;  
    }

    static updateProfile(id, saveData) {

        console.log(saveData);

        // Создаётся объект promise
        let promise = new Promise((resolve, reject) => {

            IDB.createDatabase((db) => {
                var item;
                var transaction = db.transaction("profiles", "readwrite");
                var store = transaction.objectStore("profiles");
                var getRequest = store.get(id);
    
                getRequest.onsuccess = function (event) {
                    item = event.target.result;
                    // now modify the name and date 
                    item.login = saveData.login;
                    item.name = saveData.name;
                    item.id = id;

                    console.log(item);
    
                    var putRequest = store.put(item);
                    putRequest.onsuccess = function (event) {
                        var data = event.target.result;

                        resolve(JSON.parse(JSON.stringify(data)));
                    }

                    putRequest.onerror = function() {
                        reject(putRequest.error);
                    };
                };

                getRequest.onerror = function() {
                    reject(getRequest.error);
                };
    
            });
        
        });


        return promise;


    }


    static saveProfile(id, saveData) {

        console.log(saveData);

        // Создаётся объект promise
        let promise = new Promise((resolve, reject) => {

            IDB.createDatabase((db) => {
                var item;
                var transaction = db.transaction("profiles", "readwrite");
                var store = transaction.objectStore("profiles");
                var getRequest = store.get(id);
    
                getRequest.onsuccess = function (event) {
                    item = event.target.result;
                    // now modify the name and date 
                    item.login = saveData.login;
                    item.name = saveData.name;
                    item.data = saveData.data;
                    item.id = id;

                    console.log(item);
    
                    var putRequest = store.put(item);
                    putRequest.onsuccess = function (event) {
                        var data = event.target.result;

                        resolve(JSON.parse(JSON.stringify(data)));
                    }

                    putRequest.onerror = function() {
                        reject(putRequest.error);
                    };
                };

                getRequest.onerror = function() {
                    reject(getRequest.error);
                };
    
            });
        
        });


        return promise;


    }


//====================================================================================

    static downloads() {
        // Создаётся объект promise
        let promise = new Promise((resolve, reject) => {

            IDB.createDatabase((db) => {
                var list = [];
                var item;
                var transaction = db.transaction("downloads", "readonly");
                var store = transaction.objectStore("downloads");
                var index = store.index("download_index");
                var cursor = index.openCursor();

                cursor.onsuccess = function (event) {
                    item = event.target.result;
                    if (item != null) {
                        list.push(item.value)
                        item.continue();
                    }
                    else {
                        resolve(JSON.parse(JSON.stringify(list)));
                    }
                };

                cursor.onerror = function() {
                    reject(cursor.error);
                };

            });
        
        });


        return promise;  
    }

    static getDownload(id) {

        // Создаётся объект promise
        let promise = new Promise((resolve, reject) => {

            IDB.createDatabase((db) => {
                var transaction = db.transaction("downloads", "readonly");
                var store = transaction.objectStore("downloads");
                var getRequest = store.get(id);

                getRequest.onsuccess = function (event) {
                    var item = event.target.result;
                    if (item !== undefined) {
                        resolve(JSON.parse(JSON.stringify(item)));
                    } else {
                        reject();
                    }
                };

                getRequest.onerror = function() {
                    reject(getRequest.error);
                };

            });
        
        });


        return promise;  
    }


    static saveDownload(id, saveData) {

        console.log(saveData);

        // Создаётся объект promise
        let promise = new Promise((resolve, reject) => {

            IDB.createDatabase((db) => {
                var item;
                var transaction = db.transaction("downloads", "readwrite");
                var store = transaction.objectStore("downloads");
                var getRequest = store.get(id);

                getRequest.onsuccess = function (event) {
                    item = event.target.result;
                    // now modify the name and date 
                    item.cid = saveData.cid;
                    item.name = saveData.name;
                    item.text = saveData.text;
                    item.id = id;

                    console.log(item);

                    var putRequest = store.put(item);
                    putRequest.onsuccess = function (event) {
                        var data = event.target.result;

                        resolve(JSON.parse(JSON.stringify(data)));
                    }

                    putRequest.onerror = function() {
                        reject(putRequest.error);
                    };
                };

                getRequest.onerror = function() {
                    reject(getRequest.error);
                };

            });
        
        });


        return promise;


    }

}