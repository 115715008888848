<template>
    <div class="update-profile">
        <Loader :loading="loading" />

        <Alert v-if="is_error" type="danger" message="Ошибка отправки транзакции!"/>
        <Transaction :id="transactionId"/>

        <h1>Изменить профиль</h1>

        <form class="pure-form pure-form-stacked">
                <fieldset>


                    <label for="stacked-name">Имя</label>
                    <input 
                        type="text" 
                        id="stacked-name" 
                        placeholder="Любые символы..." 
                        :value="name"
                        @input="event => name = event.target.value"
                    />
                       <span class="pure-form-message">Имя профиля будет доступно всем пользователям вашего устройства, без пароля. </span>

                    <label for="stacked-login">Логин</label>
                    <input 
                        type="text" 
                        id="stacked-login" 
                        placeholder="Латинские сиволы, цифры, _" 
                        :value="login"
                        @input="event => login = event.target.value"
                    />

                    <span class="pure-form-message">Логин профиля будет доступно всем пользователям вашего устройства, без пароля. </span>
<br />
                    <button type="submit" class="pure-button pure-button-primary"  @click="updateProfile">Сохранить</button>
                </fieldset>
        </form>


        <div class="back">
            <div @click="back"><i class="las la-arrow-left"></i>&nbsp; Назад</div>
        </div>
    
    </div>
</template>
<script>
    
import router from '@/router/router'




import Loader from '@/components/blocks/Loader.vue'
import Alert from '@/components/blocks/Alert.vue'

import IDB from '@/utils/idb'

    
import { mapState, mapGetters } from 'vuex'
    
  //  import locales from '@/utils/locales';
    
export default {
        name: "CreateProfile",
        components: {
            Loader,
            Alert
        },
        props: {
            id: String
        },
        computed: {
            ...mapState({
                "web3": state => state.app.web3,
                "currentAddress": state => state.app.currentAddress
            }),
    
            ...mapGetters({
            }),

            profileId() {
                return Number(this.id);
            },

            
            resultJson(){

                let { title, text } = this;

                return JSON.stringify({
                   title,
                   text
                });
                
            }             
        },
        methods: {
    
            back() {
                router.push("/profiles");
            },

            getProfile() {

                // ------------- Чтения данных
                IDB.getProfile(this.profileId).then((profile) => {

                    this.name = profile.name;
                    this.login = profile.login;
                    this.data = profile.data;

                }).catch(() => {

                    this.$store.dispatch('app/setAlert', { 
                        is_alert:  true,
                        type:  "danger",
                        message: "Ошибка чтения из локальной базы данных!",
                    });
                    
                });
            },
    
            updateProfile(event) {
                event.preventDefault();

                let saveData = { name: this.name, login: this.login, data: this.data  }; 

                console.log("this.profileId "+this.profileId);

                // ------------- Запись данных
                IDB.updateProfile(this.profileId, saveData).then(() => {

                    this.$store.dispatch('app/setAlert', { 
                        is_alert:  true,
                        type:  "success",
                        message: "Профиль успешно изменён!",
                    });

                    router.push("/profiles");

                }).catch(() => {

                    this.$store.dispatch('app/setAlert', { 
                        is_alert:  true,
                        type:  "danger",
                        message: "Ошибка записи в локальную базу данных!",
                    });
                    
                });

                
        
            }
            
    
        },
        data: () => ({

            name: "",
            login: "",

            loading: false,
    
            is_success: false,
            is_error: false
    
        }),
        mounted() {

            this.getProfile();
    
    
            if(this.currentAddress == "") {
               // router.push("/connect");
            } // end if
    
        }
}
</script>
    
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
    
.update-profile {
    margin: 0 auto;
    margin-top:10px;
    display: flex;
    flex-direction: column;

    max-width: 500px;

        h3 {
            font-size: 16px;
            margin-top:20px;
        }
    
        textarea {
            width:100%;
            padding:10px;
            border: 1px solid silver;
            margin-top:5px;
            height: 150px;
        }
    
        input {
            width:100%;
            padding:10px;
            border: 1px solid silver;
            margin-top:5px;
        }
    
        input[type=button] {
            padding: 5px 15px;
            margin-top:15px;
            width:auto;
            cursor: pointer;
        }
    
        input[type=checkbox] {
            width: 20px !important; 
        }
    
        select {
            width:62%;
            padding: 5px 15px;
            margin-top:5px;
            cursor: pointer;
        }

        label {
            padding:5px;
            margin-top:10px;
        }

    
        @media (max-width: 768px) {
            select {
                width:100%;
            }
    
            input {
                width:100%;
            }
        }
        
    }
    
    </style>