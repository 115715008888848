<template>
    <div class="browser-layout">
    
        <AlertModal />
        <LoginModal />
    
        <div class="top-menu-list" id="top-list-menu" v-if="show_menu">
    
            <div class="top-menu-list__header">
                <div class="logo">
                    <div class="logo-title" @click="goHome">W3UN</div>
                </div> 
                <div class="top-menu-list-close"  @click="hideMenu">
                    <i class="las la-times"></i>
                </div>
            </div>
    
            <TopMenu :currentRouteName="currentRouteName" :hideMenu="hideMenu" />
    
        </div>
        <div class="top-menu-list-blackout" id="top-menu-list-blackout"  v-if="show_menu"></div>
    
        <div class="top-menu">
            <div class="logo">
                <div class="logo-title" @click="goHome">W3UN</div>
            </div>
            <div class="address-form">
                <form class="pure-form">
                    <fieldset>
                        <input 
                            type="text" 
                            id="stacked-query" 
                            placeholder="Домен, ключ файла или адрес кошелька" 
                            :value="query"
                            @input="event => query = event.target.value"
                        /><button type="submit" class="pure-button pure-button-primary" @click="sendQuery"><i class="las la-chevron-right"></i></button>
                    </fieldset>
                </form>
            </div> 
            <div class="top-menu__bars" @click="showMenu"><i class="las la-bars"></i></div>
        </div>
    
        <div class="top-menu-addr-mobile">
            <form class="pure-form">
                <fieldset>
                    <input 
                        type="text" 
                        id="stacked-query" 
                        placeholder="Домен, ключ файла или адрес кошелька" 
                        :value="query"
                        @input="event => query = event.target.value"
                    /><button type="submit" class="pure-button pure-button-primary" @click="sendQuery"><i class="las la-chevron-right"></i></button>
                </fieldset>
            </form>
        </div>
    
        <div class="main">
           <router-view />

           query: {{ query }}
    
        </div>
    
    </div>
    
    </template>
    
    <script>
    
    import { mapState, mapGetters } from 'vuex'
    import router from '@/router/router'
    
    import AlertModal from '@/components/blocks/AlertModal.vue'
    import LoginModal from '@/components/blocks/LoginModal.vue'
    
    import TopMenu from '@/components/blocks/TopMenu.vue'
    
    export default {
        name: "BrowserLayout",
        components: {
            AlertModal,
            LoginModal,
            TopMenu
        },
        computed: {
    
            ...mapState({
                "currentProfile": state => state.app.currentProfile,
                "currentAddress": state => state.app.currentAddress,
            }),
    
            ...mapGetters({
            }),
    
            layout() {
                return this.$route.meta.layout || 'default-layout'
            }
        },
        watch: {
           '$route.path'() {
                // сохраняем текущий роут
                this.showLoginModal();
            }
        },
        methods: {
            sendQuery(event) {
                event.preventDefault();


                let query = this.query;

                let result_f = query.match(/^w3f:polygon:\/\/(0x[0-9A-Za-z]+):([0-9A-Za-z]+)/);
                let result_fid = query.match(/^w3fid:polygon:\/\/(0x[0-9A-Za-z]+):([0-9]+)/)
                let result_un = query.match(/^w3un:polygon:\/\/(.+)/);


                if(result_f) {

                    let addr = result_f[1];
                    let hash = result_f[2];

                    router.push("/browser-get-file/"+addr+"&"+hash)

                } else if(result_fid){


                    let addr = result_fid[1];
                    let fid = result_fid[2];

                    router.push("/browser-get-file/"+addr+"&"+fid)

                } else if(result_un){


                    let site = result_un[1];

                    router.push("/browser-get-site/"+site);

                } else  {
                    alert("Адрес "+this.query+" не распознан!");
                }

            },

            exitProfile() {
    
                this.$store.dispatch('app/setProfile', {     
                    profile: null,
                    password: null
                });
            },
    
            showMenu() {
                this.show_menu = true;
            },
            hideMenu() {
                this.show_menu = false;
            },
            goHome()  {
                router.push("/");
            },
    
            showLoginModal() {
                this.currentRouteName = this.$route.name;
            }
        },
        data: () => ({
            show_menu: false,
            currentRouteName: "home",
            query: ""
        }),
    
        mounted() {
    
            this.showLoginModal();
    
        }
    }
    </script>
    
    <style lang="less">
    .browser-layout {
    
        .main {
            padding-top: 10px;
            padding-left: 15px;
            padding-right: 15px;
            padding-bottom: 30px;
            width: 100%;
            margin: 0 auto;
        }
        
    
        .top-menu{
    
            display: flex;
            justify-content: space-between;

            width: 100%;
            margin: 0 auto;

            .logo {
                padding-top: 18px;
            }
    
            &__bars {
                padding-right: 15px;
                padding-top: 24px;

                cursor: pointer;
    
                i {
                    font-size:28px;
                }
            }

            .address-form {
                display: none;
            } 

            &-addr-mobile {
                display: block;
                padding: 5px 15px;
                width: 100%;

                fieldset {
                    display: flex;
                    flex-direction: row;
                    flex-grow: 1;
                    align-items: center;
                }

                input[type=text] {
                    margin: 0 5px 0 0;
                }

                button[type=submit] {
                    flex-basis: 20px;
                    margin: 0;
                }
            }

            @media (min-width: 768px) {
                &-addr-mobile {
                    display: none;  
                }

                .address-form {
                    display: block;
                    padding:5px;
                    width: 100%;

                    fieldset {
                        display: flex;
                        flex-direction: row;
                    }

                    input[type=text] {
                        margin-left: 10px;
                        margin-right: 10px;
                    }

                    button {
                        flex-basis: 20px;
                        margin-right: 10px;
                    }
                }
            }
        }
    
        .content {
            margin-top:0;
        }
    
        @media (min-width: 768px) {
    
            .content {
                margin-top:0;
            }  
        }
    
    
    
        .top-menu-list {
    
            position: fixed;
            width: 100%;
            right:0;
            top: 0;
            height:100%;
            z-index:1000;
            display: block;
            padding: 0;
    
            background-color: white;
    
            &__header {
                display: flex;
                justify-content: space-between;
    
            }
    
            &__body {
                padding-left:30px;
                padding-top: 20px;
    
            }
         }
    
        .top-menu-list-blackout {
            display: block;
        }
    
    
        @media (min-width: 768px) {
            .top-menu-list {
    
                position: fixed;
                width: 39%;
                max-width:500px;
                right:0;
                top: 0;
                height:100%;
                z-index:1000;
                display: block;
    
                background-color: white;
            }
    
            .top-menu-list-blackout {
    
                position: fixed;
                width: 100%;
                left:0;
                height:100%;
                z-index:900;
                display: block;
                opacity: 0.7;
    
                background-color: black;
            }
        }
    
        .top-menu-list-item {
            width:100%;
            padding: 12px;
            text-align: left;
    
            i {
                margin-right: 10px;
            }
    
        }
        @media (min-width: 768px) {
            .top-menu-list-item {
                width:100%;
                padding: 12px;
                text-align: left;
    
            }
        }
    
        .top-menu-list-item a {
            font-size:18px;
            color: black;
            text-decoration: none;
        }
    
        .top-menu-list-item a:hover {
            text-decoration: underline;
            color: #438BFA;
        }
    
    
        .top-menu-list-close {
            color:#232323;
            cursor: pointer;
    
            padding: 0;
            width:100%;
            text-align: right;
            font-weight: 100;
            margin-bottom:15px;
        }
    
        .top-menu-list-close i {
            margin-right:30px;
            margin-top:15px;
            font-size:28px;
        }
    
    
        @media (min-width: 768px) {
            .top-menu-list-close i {
                margin-right:25px;
                margin-top:25px;
                font-size:32px;
            }
        }
    
    
        .top-menu-list-button {
            width:100%;
            padding: 15px;
            margin: 0 auto;
            width:50%;
        }
    
    
    }
    
</style>