<template>
    <div class="get-transaction">
    
        <h1>Получить MATIC</h1>

        Для получения MATIC на свой кошёлёк, передайте адрес вашего кошелька отправителю.
            
        <div class="get-transaction__qr">
            Адрес кошелька<br /> 
            <div class="get-transaction__qr-address">{{currentAddress}} &nbsp;<i class="las la-copy" @click="() => copy()" title="Скопировать в буфер обмена"></i></div> 

            <vue-qrcode :value="qrUrl" :options="{ width: 300 }"></vue-qrcode>

        </div>

        <div class="get-transaction__button pure-button pure-button-primary" @click="back">Вернуться</div>

    </div>
</template>
 <script>
    
    import router from '@/router/router'
    

    import VueQrcode from '@chenfengyuan/vue-qrcode';

    
    import { mapState, mapGetters } from 'vuex'
    
    export default {
        name: "NewTransaction",
        components: {
          VueQrcode
        },
        props: {
        },
        computed: {
            ...mapState({
                "web3": state => state.app.web3,
                "currentAddress": state => state.app.currentAddress,
            }),
    
            ...mapGetters({
            }),

            qrUrl() {

                return "/new-transaction/"+this.currentAddress;
            }
     
        },
        methods: {

            copy() {

                if(this.currentAddress) {

                    const el = document.createElement('textarea');
                    el.value = this.currentAddress;
                    el.setAttribute('readonly', '');
                    el.style.position = 'absolute';
                    el.style.left = '-9999px';
                    document.body.appendChild(el);
                    el.select();
                    document.execCommand('copy');
                    document.body.removeChild(el);

                    alert("Адрес скопирован в буфер обмена!");
                }
            },

            back() {
                router.push("/wallet");
            }

        },
        data: () => ({
            address: "",
            amount: "",
            transactionId: "",
        }),
        mounted() {

            if(this.currentAddress == "") {
                router.push("/connect");
            } // end if


    
        }
    }
    </script>
    
    <!-- Add "scoped" attribute to limit CSS to this component only -->
    <style scoped lang="less">
    .get-transaction {
        padding:15px;
        margin-bottom:  75px;
        font-size:14px;

        display: flex;
        justify-content: center;
        flex-direction:column;
        align-items: center;

        &__button {
           width: 100%;
           margin-top:25px;
        }

        &__qr {
            margin: 0 auto;
            margin-top:5px;
            width: 100%;

            text-align: center;
            border: 2px dotted grey;
            padding: 10px 0;
            font-size:12px;
            border-radius: 5px;

            &-address {
                font-size:12px;
                margin-bottom: 20px;
                color: green;
                font-weight: bold;
                padding-top:5px;
            }

            b {
                font-size: 20px;
            }

            i {
                font-size: 20px;
                cursor: pointer;
            }

        }
        
    }
    
    </style>