<template>
    <div class="create-note">
     <Loader :loading="loading" />

    <Transaction :id="transactionId"/>

            <h1>Новая заметка</h1>

            <form class="pure-form pure-form-stacked">
                <fieldset>


                    <label for="stacked-title">Заголовок</label>
                    <input 
                        type="text" 
                        id="stacked-title" 
                        placeholder="Заголовок" 
                        :value="title"
                        @input="event => title = event.target.value"
                    />


                    <!--span class="pure-form-message">This is a required field.</span-->

                    <label for="stacked-text">Текст</label>
                    <textarea 
                        placeholder="Текст заметки.." 
                        id="stacked-text"
                        :value="text"
                        @input="event => text = event.target.value"
                    ></textarea>


                    <!--span class="pure-form-message">This is a required field.</span-->


                    <label for="stacked-key">Ключ шифрования</label>
                    <select name="folder" id="stacked-key" v-html="htmlOptions" @change="changeKey">
                    </select>

                <span class="pure-form-message">Добавить ключ шифрования</span>

                    <label for="stacked-remember" class="pure-checkbox">
                        <input type="checkbox" id="stacked-remember" /> Сохранить тело заметки в  блокчейне
                    </label>

                    <button type="submit" class="pure-button pure-button-primary"  @click="createNote">Создать</button>
                </fieldset>
            </form>


            <div class="back">
                <div @click="back"><i class="las la-arrow-left"></i>&nbsp; Назад</div>
            </div>

    
    </div>
    </template>
    <script>
    
    import router from '@/router/router'
    //import axios from 'axios'
    //import DateTime from '@/utils/datetime'
    

    import Loader from '@/components/blocks/Loader.vue'
    import Transaction from '@/components/blocks/Transaction.vue'

    import NotesABI from '@/abi/notes.json'
    
    import { mapState, mapGetters } from 'vuex'
    
  //  import locales from '@/utils/locales';
    
    export default {
        name: "CreateNote",
        components: {
            Loader,
            Transaction
        },
        props: {
        },
        computed: {
            ...mapState({
                "web3": state => state.app.web3,
                "currentAddress": state => state.app.currentAddress,
                "currentProfile": state => state.app.currentProfile,
                "currentPass": state => state.app.currentPass,
            }),
    
            ...mapGetters({
            }),

            folders() {
                let folders = this.currentProfile.data.folders;

                let keys = this.currentProfile.data.keys;


                for (let i in folders) {

                    folders[i]["keylist"] = [];

                    for (let key in keys) {
                        if(keys[key].folder == folders[i].id) {
                            folders[i]["keylist"].push(keys[key]);
                        }
                    }
                }

                return folders;

            },

            defKeyId() {

                if(this.keyId == "") {

                    let keys = this.currentProfile.data.keys;

                    let k = "";
                    for (let key in keys) {
                        if(keys[key].type == 's') {
                            k = key;
                            break;
                        }
                    }

                    return k;

                }

                return this.keyId;

            },

            htmlOptions() {
                
                let res = '<option value="0" disabled>- Без папки -</option>';

                for(let item of this.folders) {

                    res += '<option value="'+item.id+'" key="f'+item.id+(new Date().getTime())+'" disabled>'+item.name+'</option>';

                    for (let key in item.keylist) {
                        // только симетричные ключи
                        if(item.keylist[key].folder == item.id  && item.keylist[key].type == 's') {

                            let selected = key == this.defKeyId?"selected":"";

                            res += '<option value="'+item.keylist[key].login+'" key="k'+item.keylist[key].login+(new Date().getTime())+'" '+selected+'> - '+item.keylist[key].name+'</option>';

                        }
                    }
                }

                return res;

            },
            
            resultJson(){

                let keys = this.currentProfile.data.keys;

                let { title, text } = this;

                let jsonData = JSON.stringify({
                   title,
                   text
                });

                if(this.defKeyId !== "" && typeof keys[this.defKeyId] !== "undefined") {


                    let password = keys[this.defKeyId].password;
                    let type = keys[this.defKeyId].type;
                    let algo = keys[this.defKeyId].algo;

                    if(type == "s" && algo == "aes") {

                        const encryptedText = this.$CryptoJS.AES.encrypt(jsonData, password).toString();

                        console.log("s:aes:"+this.defKeyId+";"+encryptedText);

                        return "s:aes:"+this.defKeyId+";"+encryptedText;

                    }
                }

                return "err";
                
            }             
        },
        methods: {
    
            back() {
                router.push("/notes");
            },

            
            changeKey(e) {
                this.keyId = e.target.value;

            },

        
    
            createNote(event) {
                event.preventDefault();

                this.loading = true;

                this.contractToken = new this.web3.eth.Contract(NotesABI, this.notesAddress, { from: this.currentAddress });

                this.contractToken.methods.create(this.url, this.resultJson, this.defKeyId).send().then((transaction) => {

                    this.transactionId = transaction.transactionHash;

                    this.title = "";
                    this.text = "";
                    this.url = "";
                    this.keyId = "";

                }).catch(() => {

                    this.$store.dispatch('app/setAlert', { 
                        is_alert:  true,
                        type:  "danger",
                        message: "Ошибка отправки транзакции!",
                    });
                        
                }).finally(() => {

                    this.loading = false;
                });
              
    
            }
    
        },
        data: () => ({

            
            contractToken: "",

            title: "",
            text: "",
            url: "",
            keyId: "",

            //notesAddress: "0xe308ec9ABB77aC8f23e52Dce76d199be800371c5",
            notesAddress: "0x47056b8ffC059AD543BBD1CF5650671D56b953b8",


            transactionId: "",

            loading: false,
    
            is_success: false
    
        }),
        mounted() {
    
    
            if(this.currentAddress == "") {
                router.push("/connect");
            } // end if

            if(this.currentProfile === null) {
                
                this.$store.dispatch('app/setLoginModal', { 
                    show:  true
                });
            } 
    
        }
    }
    </script>
    
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
    
.create-note {
    margin: 0 auto;
    margin-top:10px;
    display: flex;
    flex-direction: column;
    max-width: 500px;
    

        h3 {
            font-size: 16px;
            margin-top:20px;
        }
    
        textarea {
            width:100%;
            padding:10px;
            border: 1px solid silver;
            margin-top:5px;
            height: 150px;
        }
    
        input {
            width:100%;
            padding:10px;
            border: 1px solid silver;
            margin-top:5px;
        }
    
        input[type=button] {
            padding: 5px 15px;
            margin-top:15px;
            width:auto;
            cursor: pointer;
        }
    
        input[type=checkbox] {
            width: 20px !important; 
        }
    
        select {
            width:62%;
            padding: 5px 15px;
            margin-top:5px;
            cursor: pointer;
        }

        label {
            padding:5px;
            margin-top:10px;
        }

    
        @media (max-width: 768px) {
            select {
                width:100%;
            }
    
            input {
                width:100%;
            }
        }
        
    }
    
    </style>