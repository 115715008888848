<template>
<div class="wallet">
    <h1>Мой кошелёк</h1>


    <div  class="alert-secondary">
        <div class="wallet__balance"><img src="/img/icon/polygon-matic-logo.svg" width="25" />{{balance}} MATIC</div>
        <div class="wallet__address">{{currentAddress}}  &nbsp;<i class="las la-copy" @click="() => copy()" title="Скопировать в буфер обмена"></i></div>



    </div>
     
</div>
</template>

<script>

//import axios from 'axios'
import router from '@/router/router'

import { mapState, mapGetters } from 'vuex'


export default {
    name: "wallet",
    components: {
    },
    props: {
    },
    computed: {

        ...mapState({
            "web3": state => state.app.web3,
            "currentAddress": state => state.app.currentAddress,
        }),

        ...mapGetters({
        }),
     
    },
    methods: {

        getBalance() {

            this.web3.eth.getBalance(this.currentAddress).then(wei => {

    
                let balanceMax =  this.web3.utils.fromWei(wei, 'ether');
                let bArr = balanceMax.split('.');
                console.log(bArr);

                this.balance =  bArr[0] + '.' + bArr[1].substr(0, 4);

                console.log(this.balance);
             
            });
        },


        copy() {

                const el = document.createElement('textarea');
                el.value = this.currentAddress;
                el.setAttribute('readonly', '');
                el.style.position = 'absolute';
                el.style.left = '-9999px';
                document.body.appendChild(el);
                el.select();
                document.execCommand('copy');
                document.body.removeChild(el);

                alert("Адрес скопирован в буфер обмена!");
        }

    },
    data: () => ({

        name: "",
        surname: "",
        patronymic: "",
        balance: 0
    }),
    mounted() {

        if(this.currentAddress == "") {
            router.push("/connect");
        } else {

            // получить данные пользователя
            this.getBalance();

        } // end if

    },

    beforeDestroy() {

    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.wallet {
    padding:15px;
    
    &__balance {
        font-size:30px;
        font-weight: bold;
        margin: 30px 0 10px 0;

        img {
            margin-right:10px;
        }
    }


    &__address {
        font-size:12px;
        margin-bottom: 20px;
        color: green;
        font-weight: bold;
        padding-top:5px;
    }

}


</style>
