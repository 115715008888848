
import { createWebHistory, createRouter } from "vue-router";

import Home from '@/components/Home.vue'

import Wallet from '@/components/wallet/Wallet.vue'
import Connect from '@/components/wallet/Connect.vue'

import NewTransaction from '@/components/transactions/NewTransaction.vue'
import GetTransaction from '@/components/transactions/GetTransaction.vue'
import ScanQR from '@/components/transactions/ScanQR.vue'

import ProShop from '@/components/pro/ProShop.vue'

import Profile from '@/components/profile/Profile.vue'
import CreateProfile from '@/components/profile/CreateProfile.vue'
import UpdateProfile from '@/components/profile/UpdateProfile.vue'

import UpdateFolder from '@/components/profile/UpdateFolder.vue'
import UpdateKey from '@/components/profile/UpdateKey.vue'

import CreateKey from '@/components/profile/CreateKey.vue'
import CreateFolder from '@/components/profile/CreateFolder.vue'

import Profiles from '@/components/profile/Profiles.vue'


import Mail from '@/components/mail/Mail.vue'

import Notes from '@/components/notes/Notes.vue'
import CreateNote from '@/components/notes/CreateNote'
import UpdateNote from '@/components/notes/UpdateNote'
import Note from '@/components/notes/Note'

import Cloud from '@/components/cloud/Cloud'
import CloudAddFile from '@/components/cloud/AddFile'
import CloudUpdateFile from '@/components/cloud/UpdateFile'
import CloudFile from '@/components/cloud/File'


import BrowserGetFile from '@/components/browser/GetFile'
import BrowserGetSite from '@/components/browser/GetSite'
import BrowserHome from '@/components/browser/BrowserHome'

import Admin from '@/components/admin/Admin.vue'
import AdminProducts from '@/components/admin/Products.vue'
import AdminNewProduct from '@/components/admin/NewProduct.vue'

import AdminCowry from '@/components/admin/Cowry.vue'



const routes = [{
        path: '/',
        name: 'home',
        component: Home
    },

    {
        path: '/admin',
        name: 'admin',
        component: Admin
    },

    {
        path: '/admin/cowry',
        name: 'admin-cowry',
        component: AdminCowry
    },
    {
        path: '/admin/products',
        name: 'admin-products',
        component: AdminProducts
    },

    {
        path: '/admin/update-product/:ucode',
        name: 'admin-update-product',
        component: AdminNewProduct,
        props: true
    },

    {
        path: '/admin/new-product',
        name: 'admin-new-product',
        component: AdminNewProduct
    },

    {
        path: '/wallet',
        name: 'wallet',
        component: Wallet
    },
    {
        path: '/connect',
        name: 'connect',
        component: Connect
    },

    {
        path: '/profile',
        name: 'profile',
        component: Profile
    },

    {
        path: '/profiles',
        name: 'profiles',
        component: Profiles
    },

    {
        path: '/create-profile',
        name: 'create-profile',
        component: CreateProfile
    },

    {
        path: '/update-profile/:id',
        name: 'update-profile',
        component: UpdateProfile,
        props: true
    },

    {
        path: '/create-profile-key',
        name: 'create-profile-key',
        component: CreateKey
    },

    {
        path: '/create-profile-folder',
        name: 'create-profile-folder',
        component: CreateFolder
    },

    {
        path: '/update-profile-folder/:id',
        name: 'update-profile-folder',
        component: UpdateFolder,
        props: true
    },

    {
        path: '/update-profile-key/:id',
        name: 'update-profile-key',
        component: UpdateKey,
        props: true
    },
    

    {
        path: '/proshop1',
        name: 'proshop',
        component: ProShop
    },

    {
        path: '/new-transaction/:addr',
        name: 'new-transaction',
        component: NewTransaction,
        props: true
    },

    {
        path: '/new-transaction',
        name: 'new-transaction',
        component: NewTransaction
    },

    {
        path: '/get-transaction',
        name: 'get-transaction',
        component: GetTransaction
    },  

    {
        path: '/scan-qr',
        name: 'scan-qr',
        component: ScanQR
    },



    // Заметки
    // Главная страница меню
    {
        path: '/notes',
        name: 'notes',
        component: Notes
    },
    {
        path: '/create-note',
        name: 'create_note',
        component: CreateNote
    },

    {
        path: '/update-note/:id',
        name: 'update_note',
        component: UpdateNote,
        props: true
    },

    {
        path: '/note/:id',
        name: 'note',
        component: Note,
        props: true
    },



    // Электронная почта
    // Главная страница меню
    {
        path: '/mail',
        name: 'mail',
        component: Mail
    },

    // Деценрализованное Оболоко
    {
        path: '/cloud',
        name: 'cloud',
        component: Cloud
    },

    {
        path: '/cloud-add-file',
        name: 'cloud_add_file',
        component: CloudAddFile
    },

    {
        path: '/cloud-file/:id',
        name: 'cloud_file',
        component: CloudFile,
        props: true
    },

    {
        path: '/cloud-update-file/:id',
        name: 'cloud_update_file',
        component: CloudUpdateFile,
        props: true
    },

    // Деценрализованные сайты
    {
        path: '/browser-get-file/:address&:id',
        name: 'browser_get_file',
        component: BrowserGetFile,
        props: true,
        meta:{ layout:'browser-layout' }
    },


    {
        path: '/browser-get-site/:site',
        name: 'browser_get_site',
        component: BrowserGetSite,
        props: true,
        meta:{ layout:'browser-layout' }
    },

    {
        path: '/browser',
        name: 'browser',
        component: BrowserHome,
        meta:{ layout:'browser-layout' }
    },

]

const router = createRouter({
    history: createWebHistory(),
    routes,
  });
  
  export default router;