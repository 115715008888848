<template>
    <div class="new-product">

        <Loader :loading="loading" />

        <h1>Создать/Обновить товар</h1>


        <div class="new-product__content">

            <Alert v-if="is_error" type="danger" :message="error_message"/>

            <Transaction v-if="transactionId" :id="transactionId" :title="transactionIdTitle"/>
            
        
            <form class="pure-form pure-form-aligned new-product__form">
                <fieldset>
                    <div class="pure-control-group">
                        <label for="aligned-code">Код товара</label>
                        <input type="text" 
                            id="aligned-code" 
                            placeholder="Например: pro" 
                            :value="code"
                            @input="event => code = event.target.value"
                            />
                        <span class="pure-form-message-inline"></span>
                    </div>
                    <div class="pure-control-group">
                        <label for="aligned-amount">Стоимость (MATIC)</label>
                        <input type="text" 
                            id="aligned-amount" 
                            placeholder="Например: 1.5" 
                            class="new-product-form__amount" 
                            :value="amount"
                            @input="event => amount = event.target.value"
                        />
                    </div>
                    <div class="pure-controls">
                        <button type="submit" class="pure-button pure-button-primary" @click="sendTransaction"><i class="las la-arrow-up"></i> Отправить</button>
                        <button class="pure-button" @click="back"><i class="las la-arrow-left"></i> Назад</button>
                    </div>
                </fieldset>
            </form>

        </div>


    </div>
</template>
<script>
    
import router from '@/router/router'
    
import ProShopABI from '@/abi/proshop.json'


import Loader from '@/components/blocks/Loader.vue'
import Alert from '@/components/blocks/Alert.vue'
import Transaction from '@/components/blocks/Transaction.vue'
    
import { mapState, mapGetters } from 'vuex'
    
export default {
        name: "NewProduct",
        components: {
            Loader,
            Alert,
            Transaction
        },
        props: {
            ucode:  {
                type: String,
                default: ""
            },
        },
        computed: {
            ...mapState({
                "web3": state => state.app.web3,
                "currentAddress": state => state.app.currentAddress,
                "proContractAddress": state => state.app.proContractAddress
            }),
    
            ...mapGetters({
            })
     
        },
        methods: {

            getProduct() {

                    this.psToken = new this.web3.eth.Contract(ProShopABI, this.proContractAddress);


                    this.loading = true;
                    this.psToken.methods.getProduct(this.code).call().then(res => {

                        this.amount = this.web3.utils.fromWei(res, 'ether');

                    }).catch(error => {

                        console.log(error);

                        this.is_error = true;
                        this.error_message = "Ошибка получения данных!";

                    }).finally(() => {

                        console.log("Transaction final");

                        this.loading = false;

                    });

            },
    
            sendTransaction(event) {
                event.preventDefault();

                this.loading = true;

                try {

                    this.contractToken = new this.web3.eth.Contract(ProShopABI, this.proContractAddress);

                    let w_amount = this.web3.utils.toWei(this.amount, 'ether');
                    let methods = this.contractToken.methods;

                    methods.setProduct(this.code, w_amount).send({ 
                        from: this.currentAddress
                    }).then(transaction => {

                        console.log(transaction);

                        this.transactionId = transaction.transactionHash;
                        this.transactionIdTitle = this.transactionId.slice(0, 5) + '...' + this.transactionId.slice(-5)

                        this.code = "";
                        this.amount = "";
                        this.is_error = false;

                    }).catch(error => {

                        console.log(error);

                        this.is_error = true;
                        this.error_message = "Ошибка отправки транзакции!";

                    }).finally(() => {
                        console.log("Transaction final");

                        this.loading = false;

                    });


                } catch (e) {
                    // инструкции для обработки ошибок
                    console.log(e); // передать объект исключения обработчику ошибок

                    this.is_error = true;
                    this.loading = false;
                }
            


                return false;
            },

            copy() {

                if(this.currentAddress) {

                    const el = document.createElement('textarea');
                    el.value = this.currentAddress;
                    el.setAttribute('readonly', '');
                    el.style.position = 'absolute';
                    el.style.left = '-9999px';
                    document.body.appendChild(el);
                    el.select();
                    document.execCommand('copy');
                    document.body.removeChild(el);

                    alert("Хеш скопирован в буфер обмена!");
                }
            },

            back() {
                router.push("/admin/products");
            }

        },
        data: () => ({
            code: "",
            amount: "",
            transactionId: "",
            transactionIdTitle: "",
            is_error: false,
            error_message: "",
            loading: false,

            contractToken: ""


        }),
        mounted() {


            if(this.currentAddress == "") {
                router.push("/connect");
            } 

            if(this.ucode !== "") {

                // входящий параметр
                this.code = this.ucode;

                this.getProduct();
            }
    
        }
}
</script>
    
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.new-product {
    margin: 0;
    margin-top:10px;

    &__content {
        background-color: #F2F2F2;
        border-radius:15px;
        padding:10px 15px 15px 15px;
        margin-bottom:15px;
        margin-top:15px;
        height:90vh;
    }
 
    
    h1 {
        color: black;
        font-size:18px;
        text-align: center;
        margin-top:15px;
        margin-bottom:20px;
        font-weight: 400;
    }

    .error {
        width:100%;
    }

    &-scan-qr {
        padding-top: 25px;

        i {
            font-size: 20px;
        }

        a{
            color: black;
            text-decoration: none;
            font-size: 14px;

            &:hover {
                color: grey;
                text-decoration: none;
            }
        }
    }

    &__form {
        margin-top:10px;
        width: 100%;



        input[type=text] {
            width: 72%;
            max-width: 700px;

            @media (max-width: 470px) { 
                width: 100%;
            }
        }

        &-amount {
            max-width: 200px !important;
        }

        button {
            margin-right:10px;

            @media (max-width: 470px) { 
                width: 100%;
                margin-right:0;
                margin-bottom:10px;
            }
        }
        
    }
}
    
</style>