<template>
  <div class="connect">
    <h1>Привязать кошелёк</h1>

   <div class="component-container"  @click="showOpt">
        <div class="component__select">
            <span class="component__select--name" v-if="selectedOption.code == ''">Выбирите блокчейн</span>
            <span class="component__select--selected" v-else> <label><img :src="selectedOption.icon"/> {{selectedOption.name}}</label></span>
            
            <span class="c-arrow-down" v-if="!showOptions"><i class="las la-angle-down"></i></span>
            <span class="c-arrow-up" v-if="showOptions"><i class="las la-angle-up"></i></span>
        </div>
        <ul class="component__select-options" v-if="showOptions" :style="{width: wselect}">
            <li :class="option.code == selectedOption.code?'select--option single-option':'select--option'"  
                v-for="(option, index) in options" :key="'pl'+index+(new Date().getTime())" @click="() => selectOption(option)">
                <label><img :src="option.icon"/> {{option.name}}</label>
            </li>
        </ul>
    </div>
 
    <p>
        <vue-metamask 
            ref="metamask" 
            :initConnect="false"
            userMessage="msg" 
            @onComplete="onComplete"
          ></vue-metamask>
        
    
        <button class="pure-button pure-button-primary" @click="connect">Вход через MetaMask</button>

    </p>
 
  </div>
</template>

<script>

import VueMetamask from 'vue-metamask';
import router from '@/router/router'

export default {
    name: 'Connect',
    props: {
    },
    components: {
        VueMetamask
    },
    methods: {

        showOpt() {

            this.showOptions = !this.showOptions;

            // подгоняем ширину выпадающего списка
            if(this.showOptions) {
                this.wselect = (document.querySelector('.component__select').offsetWidth - 2)+'px';
            }

        },

        selectOption(option) {
            this.selectedOption = option;
        },

        onComplete(data){
            console.log('data:', data);

            let web3 = data.web3;
            let currentAddress = data.metaMaskAddress;

            // сохраняем web3 
            this.$store.dispatch('app/setWeb3', { web3, currentAddress });

            router.push("/wallet");
        },

        connect() {

            switch (this.selectedOption.code) {
                case "polygon":
                    
                    this.$refs.metamask.init();  
                break;
                case "tron":
                    alert('Подключение к сети Tron в разработке...');
                break;
                case "solana":
                    alert('Подключение к сети Solana в разработке...');
                    break;
                default:
                    alert("Блокчейн не выбран!");
            }
 
        }

    },
    mounted() {

    },
    data: () => ({
       msg: "This is demo net work",

       showOptions: false,

       wselect: '300px',

       options: [
        {code: 'polygon', name: 'Polygon', icon: '/img/icon/polygon-matic-logo.svg' },
        {code: 'tron', name: 'Tron', icon: '/img/icon/tron-cryptocurrency.svg'},
        {code: 'solana', name: 'Solana', icon: '/img/icon/solana-sol-logo.svg'}
        ],
        selectedOption: {
            code: '', 
            name: '', 
            icon: '' 
        }
    }),
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.connect {
    max-width: 500px;
    margin: 0 auto;
    padding:15px;

    button {
        width: 100%;
    }



    .component__select {
        height: 38px;
        background-color: #F5F7FA;
        border: 1px solid #dddddd;
        line-height: 38px;
        display: grid;
        grid-template-columns: 10fr 1fr;
        width:100%;
    }

    .component__select--name {
        font-size: 16px;
        padding: 0 0 0 25px;
        cursor: pointer;
    }

    .component__select--selected{        
        height: 35px;
        display: grid;
        align-content: center;
        padding: 0 0 0 25px;
        cursor: pointer;

        label {
            display: flex;
            align-items: center;
            font-size: 16px;
        }

        img {
            width: 20px;
            margin-right: 15px;
        }
    }

    .c-arrow-down, .c-arrow-up {
        justify-self: end;
        padding: 3px 5px;

    }

    .c-arrow-down i, .c-arrow-up i {
        font-size:24px;
        color: silver;

    }

    .component__select-options {
        max-height: 180px;
        width:300px;
        border: 1px solid #dddddd;
        border-top: none;
        overflow: auto;
        position: absolute;
        z-index: 1500;
        margin: 0;
        padding: 0;
    }

    .select--option {
        height: 35px;
        display: grid;
        align-content: center;
        padding: 0 0 0 25px;
        background-color: #f5f5fa;
        border-bottom: 1px solid #dddddd;
        cursor: pointer;

        label {
            display: flex;
            align-items: center;
            font-size: 16px;
        }

        img {
            width: 20px;
            margin-right: 15px;
        }
    }

    .select--option:last-child {
        border-bottom: none;
    }

    .select--option:nth-child(2n) {
        background-color: #ffffff;
    }
    
    .select--option input{
        display: none;
    }

    .single-option {
        font-size: 0.8rem;
        border: 1px solid red;
    }

    .cust-sel {
        width: 200px;
        height: 38px;
        background-color: #f5f5fa;
        border: 1px solid #dddddd;
    }

    .cust-sel:focus {
        outline-width: 0;
    }
  
}
</style>
