<template>
    <div class="update-folder">
        <Loader :loading="loading" />

        <Transaction :id="transactionId"/>

        <h1>Изменить папку</h1>

        <form class="pure-form pure-form-stacked">
            <fieldset>

                <!--label for="stacked-folder">Родитель</label>
                   <select name="folder" id="stacked-folder" v-model="folder" v-html="htmlFolderOptions">
                </select-->
     
        

                <label for="stacked-name">Название</label>
                <input 
                        type="text" 
                        id="stacked-name" 
                        placeholder="Любые символы..." 
                        :value="folderName"
                        @input="event => name = event.target.value"
                />


                <button type="submit" class="pure-button pure-button-primary"  @click="updateFolder">Сохранить</button>
            </fieldset>
        </form>


        <div class="back">
            <div @click="back"><i class="las la-arrow-left"></i>&nbsp; Профиль</div>
        </div>
    
    </div>
</template>
<script>
    
import router from '@/router/router'
import IDB from '@/utils/idb'
import Loader from '@/components/blocks/Loader.vue'

    
import { mapState, mapGetters } from 'vuex'

    
export default {
        name: "UpdateFolder",
        components: {
            Loader
        },
        props: {
            id: String
        },
        computed: {
            ...mapState({
                "web3": state => state.app.web3,
                "currentAddress": state => state.app.currentAddress,
                "currentProfile": state => state.app.currentProfile,
                "currentPass": state => state.app.currentPass,
            }),
    
            ...mapGetters({
            }),

            folders() {

                let folders = this.currentProfile.data.folders;

               return folders;

            },

            htmlFolderOptions() {

                let res = '<option value="0">- Без родителя -</option>';

                for(let item of this.folders) {
                    res += '<option value="'+item.id+'" key="f'+item.id+(new Date().getTime())+'">'+item.name+'</option>';

                    if(typeof item.children !== "undefined" && item.children.length > 0) {
                        for(let item2 of item.children) {
                            res += '<option value="'+item2.id+'" key="f'+item2.id+(new Date().getTime())+'"> - '+item2.name+'</option>';
                        
                            if(typeof item2.children !== "undefined" && item2.children.length > 0) {
                                for(let item3 of item2.children) {
                                    res += '<option value="'+item3.id+'" key="f'+item3.id+(new Date().getTime())+'"> - '+item3.name+'</option>';
                                }
                            }
                        
                        }
                    }
                }

                return res;

            },
                    

            folderName() {

                if(this.name.trim() == "") {
                    let folders = this.currentProfile.data.folders;

                    let folder = folders.find(item => item.id == Number(this.id));

                    return folder.name;

                }

                return this.name;
            },

           
        },
        methods: {
    
            back() {
                router.push("/profile");
            },
    
            updateFolder(event) {
                event.preventDefault();


                if(this.currentProfile == null) {

                    this.$store.dispatch('app/setLoginModal', { 
                       show:  true
                    });

                } else {


                    let profile = this.currentProfile;
                    let profileId = profile.id;
                    let keys = profile.data.keys;

                    let { name } = this;


                    let folders = profile.data.folders;
                    for(let i in folders) {
                        if(folders[i].id == Number(this.id)) folders[i].name = name;
                    }
              
                    let data = { 
                        text: profile.text, 
                        keys, 
                        folders
                    };

                    let jsonData = JSON.stringify(data);
                    const encryptedText = this.$CryptoJS.AES.encrypt(jsonData, this.currentPass).toString();

                    let saveData = { name: profile.name, login: profile.login, data: encryptedText  }; 

                    // ------------- Запись данных
                    IDB.saveProfile(profileId, saveData).then(() => {


                        let myprofile = {
                            id: profileId,
                            name: profile.name,
                            login: profile.login,
                            data
                        };

                        this.$store.dispatch('app/setProfile', { 
                            profile: myprofile,
                            password: this.currentPass,
                        });

                        router.push("/profile");

                        this.$store.dispatch('app/setAlert', { 
                            is_alert:  true,
                            type:  "success",
                            message: "Данные сохранены!",
                        });
                           

                    }).catch(() => {

                        this.$store.dispatch('app/setAlert', { 
                            is_alert:  true,
                            type:  "danger",
                            message: "Ошибка сохранения данных!",
                        });
                            
                    });

               

                } // end if
         
            }
            
    
        },
        data: () => ({

 
            folder: 0,
            name: "",

            loading: false,
    
            is_success: false,
            is_error: false
    
        }),
        watch: {
            '$route'(){
                
              //  this.name = this.folderName();
            }
        },

        mounted() {
    
    
            if(this.currentAddress == "") {
               // router.push("/connect");
            } // end if

            if(this.currentProfile === null) {
                
                this.$store.dispatch('app/setLoginModal', { 
                    show:  true
                });
            }

    
        }
}
</script>
    
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
    
.update-folder {
    margin: 0;
    margin-top:10px;
    display: flex;
    flex-direction: column;

    &__header {
        padding-top: 5px;
        padding-bottom: 15px;
    }

    &__content {
        border-radius:15px 15px 0 0;
        padding:10px 15px 15px 15px;
            

        h2 {
            font-size:18px;
            margin-top:30px;
        }
    }
    

        h3 {
            font-size: 16px;
            margin-top:20px;
        }
    
        textarea {
            width:100%;
            padding:10px;
            border: 1px solid silver;
            margin-top:5px;
            height: 150px;
        }
    
        input {
            width:62%;
            padding:10px;
            border: 1px solid silver;
            margin-top:5px;
        }
    
        input[type=button] {
            padding: 5px 15px;
            margin-top:15px;
            width:auto;
            cursor: pointer;
        }
    
        input[type=checkbox] {
            width: 20px !important; 
        }
    
        select {
            width:62%;
            padding: 5px 15px;
            margin-top:5px;
            cursor: pointer;
        }

        label {
            padding:5px;
            margin-top:10px;
        }

    
        @media (max-width: 768px) {
            select {
                width:100%;
            }
    
            input {
                width:100%;
            }
        }
        
    }
    
    </style>