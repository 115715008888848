<template>
    <div class="get-file">

        <Loader :loading="loading" />

        <Alert v-if="is_error" type="danger" message="Ошибка получения данных!"/>

            <div class="get-file__item"> 
                <div class="get-file__item-header" v-if="title">
                    <div class="get-file__item-title">{{ title }}</div>
                </div>
                <div class="get-file__item-text">

                    <div class="get-file__item-inp">

                        <div v-if="!no_encrypt" class="get-file__item-inp-noencrypt">
                            Файл доступен только по паролю.<br />

                            <form class="pure-form">
                                <fieldset>
                                    <legend>Введите пароль:</legend>
                                    <input type="password" placeholder="Пароль" />
                                    <button type="submit" class="pure-button pure-button-primary">Расшифровать</button>
                                </fieldset>
                            </form>

                        </div>

                        <div v-if="name !== ''">
                            Имя: <b>{{name}}</b> <br />
                            Размер:  <b>{{formatBytes(size)}}</b> <br />
                            Тип:  <b>{{type}}</b> <br />
                            ID: <b>{{ id }}</b> <br />
                            Адрес: <b>{{ address }}</b>
                        </div>

                        <div class="get-file__item-inp-image" @click="fileImageMin" v-if="is_image">
                            <img :src="content"  
                                :class="is_image_min?'file__item-inp-image-min':'file__item-inp-image-max'"
                            />
                        </div>

                        <div class="file__item-inp-link" v-if="name !== ''">
                            <i class="las la-file-download"></i> <a :download="name" :href="content">Скачать</a>
                        </div>

                        <div class="file__item-inp-link" v-if="name !== ''" @click="copy(address, id)">
                            <i class="las la-share-alt-square"></i> Поделиться
                        </div>
                   </div>

                </div>
                <div class="file__item-footer">
                    <div class="file__item-create">{{ getCreateDate(ts, ts_update) }}</div>
                    <div class="file__item-date">{{ getDate(ts, ts_update) }}</div>
                </div>
            </div> 
   </div>
 </template>
 
 <script>
 import router from '@/router/router'

 import DT from '@/utils/dt'
 import Utils from '@/utils/utils'


 import { mapState, mapGetters } from 'vuex'

import Loader from '@/components/blocks/Loader.vue'
import Alert from '@/components/blocks/Alert.vue'

import CloudABI from '@/abi/cloud.json'
    
 
 export default {
     name: 'CloudGetFile',
     components: {
        Loader,
        Alert
    },
     props: {
        id: String,
        address: String
     },
     computed: {
            ...mapState({
                "web3": state => state.app.web3,
                "currentAddress": state => state.app.currentAddress,
                "currentProfile": state => state.app.currentProfile,
                "currentPass": state => state.app.currentPass,
            }),
    
            ...mapGetters({
            })
     
     },
     methods: {

        copy(address, id) {

            let link = "w3fid:polygon://"+address+":"+id;

            const el = document.createElement('textarea');
            el.value = link;
            el.setAttribute('readonly', '');
            el.style.position = 'absolute';
            el.style.left = '-9999px';
            document.body.appendChild(el);
            el.select();
            document.execCommand('copy');
            document.body.removeChild(el);

            alert("Ссылка скопирована в буфер обмена!");
        },

        back() {
            router.push("/cloud");
        },
            
        changeKey(e) {
            this.keyId = e.target.value;

        },

        fileImageMin() {
            this.is_image_min = !this.is_image_min;
        },

        formatBytes(size) {
            return Utils.formatBytes(size);
        },

        getKeyPassword(keyId) {
                let profile = this.currentProfile;
                let keys = profile.data.keys;

                return keys[keyId].password;
        },

            
        is_image() {
            return this.type == "image/jpeg" || this.type == "image/png" || this.type == "image/gif";
        },

        getFile() {

            let fileId = Number(this.id);
            let address = this.address;

            this.token = new this.web3.eth.Contract(CloudABI, this.cloudAddress, { from: this.currentAddress });

                this.token.methods.readFileById(address, fileId).call().then((res) => {

                    console.log(res);

                    try {

                        // если текст не шифрованный парсим Json
                        if(res.text[0] == "{") {

                            let obj = JSON.parse(res.text);

                            this.title = obj.title;
                            this.name  = obj.name;
                            this.size  = obj.size;
                            this.type  = obj.type;
                            this.content  = obj.content;

                            this.no_encrypt = true;

                        // если текст шифрованный, дешифруем и парсим Json
                        } else {
                            

                            let codes = res.text.split(';');
                            let subcodes = codes[0].split(':');

                            if(subcodes[0] == 's' && subcodes[1] == 'aes') {
                                const decryptedText = this.$CryptoJS.AES.decrypt(codes[1], this.getKeyPassword(subcodes[2])).toString(this.$CryptoJS.enc.Utf8);

                                console.log(decryptedText);
                            

                                let obj = JSON.parse(decryptedText);

                                this.title = obj.title;
                                this.name  = obj.name;
                                this.size  = obj.size;
                                this.type  = obj.type;
                                this.content  = obj.content;
                            }

                        }

                        this.keyId = res.keyId;
                        this.ts = res.ts;
                        this.ts_update = res.ts_update;

                    } catch (error) {

                        this.title = "Не удалось расшифровать!";
                    }

                }).catch(() => {

                    this.$store.dispatch('app/setAlert', { 
                        is_alert:  true,
                        type:  "danger",
                        message: "Ошибка получения данных!",
                    });

                    
                }).finally(() => {

                   this.loading = false;
                });


        },

        getCreateDate(ts, ts_update) {
            if(ts == ts_update) {
                return "";
            }

            return DT.getFormatDate(ts+"");
        },

        getDate(ts, ts_update) {
            if(ts == ts_update) {
                return DT.getFormatDate(ts+"");
            }

            return "обн. "+DT.getFormatDate(ts_update+"");
        },




     },
     data: () => ({
        
            no_encrypt: false,

            title:"",
            name: "",
            size: "",
            type: "",
            content: "",
            is_image_min: true,

            
            contractToken: "",

            text: "",
            keyId: "",

        // cloudAddress: "0x31f34d7E4D475469A58c91C54fb42E5fbEba78C5",
        cloudAddress: "0xf3DB4c7a06a2154EbE1CD88024EF3641CB9b24e5",

        loading: false
    }),

    mounted() {

        if(this.currentAddress == "") {
            router.push("/connect");

        } else {
            this.getFile();
        }

        
    }
 }
 </script>
 
 <style scoped lang="less">
 .get-file {
    display: flex;
    flex-direction: column;

    padding-top: 10px;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 30px;
    max-width: 800px;
    margin: 0 auto;

    &__header {
        padding-top: 5px;
        padding-bottom: 15px;
    }

    &__content {
        padding:10px 15px 15px 15px;
            

        h2 {
            font-size:18px;
            margin-top:30px;
        }
    }

    &__item {
            border: 1px solid silver;
            border-radius: 5px;
            margin-bottom:10px;
            margin-top:10px;

            &-inp {

                padding: 0;
                margin-top:10px;

                &-noencrypt {
                    padding-bottom: 10px;
                    font-weight: bold;
                    font-size:14px;
                    color: green;
                }

                &-link {
                    margin-top: 20px;
                    margin-bottom: 10px;

                    a {
                        color: black;
                        text-decoration: none;
                    }

                    i {
                        font-size:20px;
                    }
                }

                input[type=file] {
                    width:100%;
                    padding:0;
                    border: 0;
                    margin-top:5px;
                    margin-bottom:5px;
                }

                &-image {
                    margin-top:10px;
                    cursor: pointer;

                    &-min {
                        max-width: 150px;
                    }

                    &-max {
                        width: 95%;
                        max-width:none;
                    }
                }

            }

            &-header {
                border-bottom: 1px solid silver;
            }
            
            &-title {
                font-size:16px;
                font-weight: bold;
                padding:10px;
                word-break: break-all;

            }

            &-text {
                font-size:14px;
                padding:10px;
                word-break: break-all;
            }


            &-footer {
                display: flex;
                justify-content: space-between;
                padding:0;
            }

            &-create {
                font-size:12px;
                display: flex;
                justify-content: left;
                align-items: center;
                color: grey;
                padding:10px 10px 0 10px;
            }


            &-date {
                font-size:12px;
                display: flex;
                justify-content: center;
                align-items: center;
                color: grey;
                padding:10px;

            }
    }
 }
 </style>