<template>
    <div class="note">

        <Loader :loading="loading" />

        <Alert v-if="is_error" type="danger" message="Ошибка получения данных!"/>


            <div class="note__item" > 
                <div class="note__item-header">
                    <div class="note__item-title">{{ title }}</div>
                </div>
                <div class="note__item-text" v-html="textControl(text)" >
                </div>
                <div class="note__item-create">{{ getCreateDate(ts, ts_update) }}</div>
                <div class="note__item-footer">
                    <div class="note__item-date">{{ getDate(ts, ts_update) }}</div>
                    <div class="note__item-title">
                        <router-link class="pure-button" :to="'/update-note/'+noteId">
                            <i class="las la-pencil-alt"></i>
                        </router-link>
                        &nbsp;
                        <a href="" class="pure-button" @click="e => removeNote(e, noteId)">
                            <i class="las la-times"></i>
                        </a>
                    </div>
                </div>
            </div>

            <div class="back">
                <div @click="back"><i class="las la-arrow-left"></i>&nbsp; Назад</div>
            </div>  
   </div>
 </template>
 
 <script>
 import router from '@/router/router'

 import DT from '@/utils/dt'
 import Utils from '@/utils/utils'


 import { mapState, mapGetters } from 'vuex'

import Loader from '@/components/blocks/Loader.vue'
import Alert from '@/components/blocks/Alert.vue'
import NotesABI from '@/abi/notes.json'
 
 export default {
     name: 'Note',
     components: {
        Loader,
        Alert
    },
     props: {
        id: String
     },
     computed: {
            ...mapState({
                "web3": state => state.app.web3,
                "currentAddress": state => state.app.currentAddress,
                "currentProfile": state => state.app.currentProfile,
                "currentPass": state => state.app.currentPass,
            }),
    
            ...mapGetters({
            })
     
     },
     methods: {

        back() {
            router.push("/notes");
        },

        getKeyPassword(keyId) {
            let profile = this.currentProfile;
            let keys = profile.data.keys;

            return keys[keyId].password;
        },

        textControl(str) {

            // удаляем теги
            let res = Utils.cutTags(str);

            // заменяем plain-url на html ссылки
            res = Utils.linkify(res);

            // замена переносов на br
            res = Utils.nl2br(res);

            return res;
        },

        getCreateDate(ts, ts_update) {
            if(ts == ts_update) {
                return "";
            }

            return DT.getFormatDate(ts+"");
        },

        getDate(ts, ts_update) {
            if(ts == ts_update) {
                return DT.getFormatDate(ts+"");
            }

            return "обн. "+DT.getFormatDate(ts_update+"");
        },


        removeNote(event, noteId) {
            event.preventDefault();

            this.loading = true;

            this.contractToken = new this.web3.eth.Contract(NotesABI, this.notesAddress, { from: this.currentAddress });

            this.contractToken.methods.remove(Number(noteId))
                    .send().then((transaction) => {

                this.transactionId = transaction.transactionHash;
                this.transactionIdTitle = this.transactionId.slice(0, 5) + '...' + this.transactionId.slice(-5);

                router.push("/notes");

            }).catch(() => {
                this.is_error = true;
                        
            }).finally(() => {

                this.loading = false;
            });
    
        },

        getNote() {

            this.token = new this.web3.eth.Contract(NotesABI, this.notesAddress, { from: this.currentAddress });

            this.loading = true;
            this.token.methods.read(this.noteId).call().then((note) => {

                let  title = "";
                let text = "";


                try {

                    if(note.text == ":d;") {

                        title = "Запись удалена...";
                        text  = "Вы можете редактировать эту запись";

                    } else {

                        let codes = note.text.split(';');
                        let subcodes = codes[0].split(':');

                        if(subcodes[0] == 's' && subcodes[1] == 'aes') {
                            const decryptedText = this.$CryptoJS.AES.decrypt(codes[1], this.getKeyPassword(subcodes[2])).toString(this.$CryptoJS.enc.Utf8);

                            console.log(decryptedText);


                            let obj = JSON.parse(decryptedText);

                            title = obj.title;
                            text  = obj.text;

                        }

                    }

                } catch (error) {

                    console.log(error);

                    title = "Не удалось расшифровать!";
                    text  = "Исходный текст: "+note.text;
                }


                this.title = title;
                this.text = text;
                this.ts = note.ts;
                this.ts_update = note.ts_update;




            }).catch(() => {
               this.is_error = true;
                    
            }).finally(() => {

               this.loading = false;

               console.log("finally");
            });
                    

        },


     },
     data: () => ({
        
        noteId:0,
        title: "",
        text: "",
        ts:0,
        ts_update: 0,

        token:null,

        //notesAddress: "0xe308ec9ABB77aC8f23e52Dce76d199be800371c5",
        notesAddress: "0x47056b8ffC059AD543BBD1CF5650671D56b953b8",

        loading: false
    }),

    mounted() {

        console.log("this.currentAddress: "+this.currentAddress);
 

        if(this.currentAddress == "") {
            router.push("/connect");

        } else {

            this.noteId = Number(this.id);

            this.getNote();
 
        } // end if
        
    }
 }
 </script>
 
 <style scoped lang="less">
 .note {
    margin: 0;
    margin-top:10px;
    display: flex;
    flex-direction: column;

    &__header {
        padding-top: 5px;
        padding-bottom: 15px;
    }

    &__content {
        padding:10px 15px 15px 15px;
            

        h2 {
            font-size:18px;
            margin-top:30px;
        }
    }

    &__item {
            border: 1px solid silver;
            border-radius: 5px;
            margin-bottom:10px;
            margin-top:10px;

            &-header {
                border-bottom: 1px solid silver;
            }
            
            &-title {
                font-size:16px;
                font-weight: bold;
                padding:10px;
                word-break: break-all;

            }

            &-text {
                font-size:14px;
                padding:10px;
                word-break: break-all;
            }

            &-create {
                font-size:12px;
                display: flex;
                justify-content: left;
                align-items: center;
                color: grey;
                padding:10px 10px 0 10px;
            }

            &-footer {
                display: flex;
                justify-content: space-between;
                padding:0;
            }


            &-date {
                font-size:12px;
                display: flex;
                justify-content: center;
                align-items: center;
                color: grey;
                padding:10px;

            }


            &-update {
                font-size:14px;
                padding:0;
            }
    }
 }
 </style>