<template>
    <div class="admin-cowry">
        <Loader :loading="loading" />

        <div class="header">
            <h1>Каури</h1>
        </div>

        <div class="content admin-cowry__content  greybg"> 

            <Alert v-if="is_error" type="danger" :message="error_message"/>

            <Transaction v-if="transactionId" :id="transactionId" :title="transactionIdTitle"/>



          <h2>Каури</h2>

        </div>
    </div>
</template>
    
<script>
    
    import router from '@/router/router'
    
    import { mapState, mapGetters } from 'vuex'

    import CowryABI from '@/abi/cowry.json'

    import Loader from '@/components/blocks/Loader.vue'
    import Alert from '@/components/blocks/Alert.vue'
    import Transaction from '@/components/blocks/Transaction.vue'
    
    
    export default {
        name: "AdminProducts",
        components: {
            Loader,
            Alert,
            Transaction
        },
        props: {
        },
        computed: {
    
            ...mapState({
                "web3": state => state.app.web3,
                "currentAddress": state => state.app.currentAddress,
                "proContractAddress": state => state.app.proContractAddress
            }),
    
            ...mapGetters({
            }),
         
        },
        methods: {

            getTotalSupply() {

                this.cowryToken = new this.web3.eth.Contract(CowryABI, this.proContractAddress);

                this.loading = true;


                console.log(this.cowryToken.methods);


                this.cowryToken.methods.symbol().call().then(res => {

    
                    console.log(res);

                });

                /*
                this.cowryToken.methods.totalSupply().call().then(res => {

    
                    console.log(res);

                }).catch(error => {

                    console.log(error);

                    this.is_error = true;
                    this.error_message = "Ошибка получения данных!";

                }).finally(() => {

                    console.log("Transaction final");

                    this.loading = false;

                });
                */
    
            },

            /*
            update(code) {
                router.push("/admin/update-product/"+code);
            },



            remove(code) {
         
                this.psToken = new this.web3.eth.Contract(ProShopABI, this.proContractAddress);


                this.loading = true;
                this.psToken.methods.removeProduct(code).send({ 
                    from: this.currentAddress
                }).then(transaction => {

                    console.log(transaction);

                    this.transactionId = transaction.transactionHash;
                    this.transactionIdTitle = this.transactionId.slice(0, 5) + '...' + this.transactionId.slice(-5);

                    this.getProducts();

                }).catch(error => {

                    console.log(error);

                    this.is_error = true;
                    this.error_message = "Ошибка удаления данных!";

                }).finally(() => {

                    console.log("Transaction final");

                    this.loading = false;

                });
         

            }
            */

           
    
        },
        data: () => ({
    
            is_error: false,
            error_message: "",
            loading: false,

            products: [],

            transactionId:"",
            transactionIdTitle: "",

            psToken:null
        }),
        mounted() {
    
            if(this.currentAddress == "") {
                router.push("/connect");
            } else {
    
                // товары
              //  this.getProducts();

               this.getTotalSupply();
    
            } // end if
    
        },
    
        beforeDestroy() {
    
        }
    }
</script>
    
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
    .admin-cowry {
        margin: 0;
        margin-top:10px;
        display: flex;
        flex-direction: column;

        .button-error {
            background: rgb(202, 60, 60);
            color:white;
            /* this is a maroon */
        }

        &__content {
            border-radius:15px 15px 0 0;
            padding:10px 15px 15px 15px;
            flex-basis: 95%;
        }
    
    }
    
    
</style>
    