<template>
    <div class="notes">

     <Loader :loading="loading" />

       <h1>Заметки ({{ countNotes }})</h1>   


        <Alert v-if="is_error" type="danger" :message="error_text"/>

        <Transaction :id="transactionId" :title="transactionIdTitle"/>

        <div class="notes__newnote">
                    <div class="notes__newnote-search">
                        <input type="text" name="search" value="" placeholder="Поиск по заметкам..." />
                    </div>
                    <div class="notes__newnote-but">
                        <router-link to="/create-note"><i class="las la-plus-square"></i> Новая заметка</router-link>
                    </div>
        </div>



        <div class="notes__list" id="content-scroll">
            <div class="notes__list-item"  v-for="(item, index) in listNotes" :key="'pr'+index+(new Date().getTime())"> 
                    <div class="notes__list-item-header">
                        <div class="notes__list-item-title"><router-link :to="'/note/'+item.noteId">{{ item.title }}</router-link></div>
                    </div>
                    <div class="notes__list-item-text-container">
                    <div class="notes__list-item-text">
                       <span v-html="textCut(item.text)"></span>  
                        <router-link v-if="is_limit(item.text)" :to="'/note/'+item.noteId">
                            Ещё 
                        </router-link>
                    </div>
                   </div>
                    <div class="notes__list-item-create">{{ getCreateDate(item.ts, item.ts_update) }}</div>
                    <div class="notes__list-item-footer">
                        <div class="notes__list-item-date">{{ getDate(item.ts, item.ts_update) }}</div>
                        <div class="notes__list-item-title">
                            <router-link class="pure-button" :to="'/update-note/'+item.noteId">
                                <i class="las la-pencil-alt"></i>
                            </router-link>
                            &nbsp;
                            <a href="" class="pure-button" @click="e => removeNote(e, item.noteId)">
                                <i class="las la-times"></i>
                            </a>
                        </div>
                    </div>
            </div>
        </div>
  
   </div>
 </template>
 
 <script>
 import router from '@/router/router'
 import parser from 'bbcode-parser-sax'

 import DT from '@/utils/dt'
 import Utils from '@/utils/utils'

 import { mapState, mapGetters } from 'vuex'

import Loader from '@/components/blocks/Loader.vue'
import Alert from '@/components/blocks/Alert.vue'
import Transaction from '@/components/blocks/Transaction.vue'


import NotesABI from '@/abi/notes.json'
 
 export default {
     name: 'Notes',
     components: {
            Loader,
            Alert,
            Transaction
    },
     props: {
     },
     computed: {
            ...mapState({
                "web3": state => state.app.web3,
                "currentAddress": state => state.app.currentAddress,
                "currentProfile": state => state.app.currentProfile,
                "currentPass": state => state.app.currentPass,
            }),
    
            ...mapGetters({
            }),

            listNotes() {
                return this.notes.filter(item => Number(item.noteId) != 0);
            },


     
     },
     methods: {

        getKeyPassword(keyId) {
            let profile = this.currentProfile;
            let keys = profile.data.keys;

            return keys[keyId].password;
        },

 
        textCut(str) {

            // удаляем теги
            let res = Utils.cutTags(str);

            // обрезаем строку
            res = Utils.kitcut(res, this.limit);

            // замена переносов на br
            res = Utils.nl2br(res);

            return res;
        },

        is_limit(str) {

            // удаляем теги
            let res = Utils.cutTags(str);

            return res.length > this.limit;

        },

        getCreateDate(ts, ts_update) {
            if(ts == ts_update) {
                return "";
            }

            return DT.getFormatDate(ts+"");
        },

        getDate(ts, ts_update) {
            if(ts == ts_update) {
                return DT.getFormatDate(ts+"");
            }

            return "обн. "+DT.getFormatDate(ts_update+"");
        },

        getCountNotes(callback) {

            this.token = new this.web3.eth.Contract(NotesABI, this.notesAddress, { from: this.currentAddress });

            this.listLoading = true;

            this.token.methods.getCountNotes().call().then((count) => {
                callback(count);

            }).catch((err) => {
                if (err) {

                    this.$store.dispatch('app/setAlert', { 
                        is_alert:  true,
                        type:  "danger",
                        message: "Ошибка получения данных!",
                    });

                    return
                }
                            
            }).finally(() => {
                this.listLoading = false;
            });



        },

        getNotesDesc(noteId, count) {



            this.token = new this.web3.eth.Contract(NotesABI, this.notesAddress, { from: this.currentAddress });


            this.loading = true;

            this.token.methods.getNotesDesc(noteId, count).call().then((res) => {


                
                console.log(res);

            
                let newNotes = res.map((item) => { 

                    let  title = "";
                    let text = "";

                    try {

                        if(item.text == ":d;") {

                            title = "Запись удалена...";
                            text  = "Вы можете редактировать эту запись";

                        } else {

                            let codes = item.text.split(';');
                            let subcodes = codes[0].split(':');

                            if(subcodes[0] == 's' && subcodes[1] == 'aes') {
                                const decryptedText = this.$CryptoJS.AES.decrypt(codes[1], this.getKeyPassword(subcodes[2])).toString(this.$CryptoJS.enc.Utf8);

                                console.log(decryptedText);
                          

                                let obj = JSON.parse(decryptedText);

                                title = obj.title;
                                text  = obj.text;

                            }

                        }
                    } catch (error) {

                        title = "Не удалось расшифровать!";
                        text  = "Исходный текст: "+item.text;
                    }

                    return { noteId: item.noteId, title, text, ts: item.ts, ts_update: item.ts_update };

                });

                this.newNotes = newNotes;

                // удаляем дубликаты
                let resNotes = [];
                for(let i in this.notes) {

                    let is_dub = false;
                    for(let n in this.newNotes) {
                        if(this.notes[i].noteId == this.newNotes[n].noteId) {
                            is_dub =  true;
                        }
                    }

                    if(!is_dub) resNotes.push(this.notes[i]);
                }

                // добавляеем загруженные записи в список
                resNotes.push(...newNotes);

                this.notes = resNotes;

            }).catch(() => {

                this.$store.dispatch('app/setAlert', { 
                    is_alert:  true,
                    type:  "danger",
                    message: "Ошибка получения данных!",
                });
                
            }).finally(() => {

                this.loading = false;

                console.log("finally");
            });
            

        },

        getNotes(noteId, count) {

            this.token = new this.web3.eth.Contract(NotesABI, this.notesAddress, { from: this.currentAddress });

            this.token.methods.getNotes(noteId, count).call((err, res) => {
                if (err) {
                    
                    this.$store.dispatch('app/setAlert', { 
                        is_alert:  true,
                        type:  "danger",
                        message: "Ошибка получения данных!"
                    });

                    return
                }

                console.log(res);

               
                    this.allnotes = res.map((item) => { 

                        let  title = "";
                        let text = "";


                        try {

                            if(item.text == ":d;") {

                                title = "Запись удалена...";
                                text  = "Вы можете редактировать эту запись";

                            } else {

                                let codes = item.text.split(';');
                                let subcodes = codes[0].split(':');

                                if(subcodes[0] == 's' && subcodes[1] == 'aes') {
                                    const decryptedText = this.$CryptoJS.AES.decrypt(codes[1], this.getKeyPassword(subcodes[2])).toString(this.$CryptoJS.enc.Utf8);

                                    console.log(decryptedText);
                            

                                    let obj = JSON.parse(decryptedText);

                                    title = obj.title;
                                    text  = obj.text;

                                }

                            }

                        } catch (error) {

                            title = "Не удалось расшифровать!";
                            text  = "Исходный текст: "+item.text;
                        }

                        return { noteId: item.noteId, title, text };

                    });

            })

        },

        removeNote(event, noteId) {
            event.preventDefault();

            this.loading = true;

            this.contractToken = new this.web3.eth.Contract(NotesABI, this.notesAddress, { from: this.currentAddress });

            this.contractToken.methods.remove(Number(noteId))
                    .send().then((transaction) => {

                this.transactionId = transaction.transactionHash;
                this.transactionIdTitle = this.transactionId.slice(0, 5) + '...' + this.transactionId.slice(-5);

                // получаем данные
                this.start();

            }).catch(() => {

                this.$store.dispatch('app/setAlert', { 
                    is_alert:  true,
                    type:  "danger",
                    message: "Ошибка отправки транзакции!",
                });
                        
            }).finally(() => {

                this.loading = false;
            });
    
        },


        contentScroll() {


            let block = document.getElementById('content-scroll');

            // получение последнего элемента массива
            let newNotes = this.newNotes;
            let lastItem = newNotes[newNotes.length - 1];

            console.log("lastItemId: ");
            console.log(lastItem);

            if(typeof  lastItem !== "undefined" && typeof lastItem.noteId !== "undefined") {
                let lastItemId = Number(lastItem.noteId);
     
                let contentHeight = block.offsetHeight; 
                let yo = window.scrollY;
                let wh = window.innerHeight;
                let y = yo + wh;

        

                // если пользователь достиг конца
                if(y >= contentHeight && this.isScroll && lastItemId > 0)
                {
                    //загружаем новое содержимое в элемент
                   this.getNotesDesc(lastItemId, 5);


                    this.isScroll = false;

                    setTimeout(() => {
                        this.isScroll = true;
                    }, 1000); 
                }

            } // end if

        },

        start() {

            let BBCODE_STRING = "The quick brown aaaaaaaaaa fox [i]jumped[/i] [b]over[/b] the [u]stream[/u]";

            console.log(parser.parse(BBCODE_STRING));

            // получаем данные
            this.getNotesDesc(this.countNotes, 5);

        }
 
     },

    watch: {
        '$route'(){
            
            this.start();
        }
    },

    data: () => ({
        loading: false,

        newNotes: [],
        notes: [],

        limit: 512, // кол-во символов превью

        allnotes: [],

        token:null,

        //notesAddress: "0xe308ec9ABB77aC8f23e52Dce76d199be800371c5",
        notesAddress: "0x47056b8ffC059AD543BBD1CF5650671D56b953b8",

        transactionId: "",
        transactionIdTitle: "",

        countNotes: 0,
        isScroll: true
     }),
     mounted() {

        console.log("this.currentAddress: "+this.currentAddress);
 

         if(this.currentAddress == "") {
            router.push("/connect");

        } else {

            window.onscroll = () => {

               // this.contentScroll();
            };

          //  this.getNote(1);

            this.getCountNotes(countNotes => {

                // сохраняем общее кол-во записей
                this.countNotes = countNotes;

                // получаем данные
                this.start();


                //this.getNotes(1, countNotes);
            });
 
         } // end if


        if(this.currentProfile === null) {
                
            this.$store.dispatch('app/setLoginModal', { 
                show:  true
            });
        }
 
    },

    beforeDestroy() {

        //  удаление бесконечной загрузки на скролин
      //  window.removeEventListener("scroll", this.contentScroll, false);

    }

 }
 </script>
 
 <style scoped lang="less">

.notes {
    margin: 0;
    display: flex;
    flex-direction: column;

    h1 {
        padding: 0 10px;
        margin-top: 20px;
    }

    &__list {
        padding:10px;

        &-item {
            border: 1px solid silver;
            border-radius: 5px;
            margin-bottom:10px;

            &-header {
                border-bottom: 1px solid silver;
            }
            
            &-title {
                font-size:16px;
                font-weight: bold;
                padding:10px;

                a {
                    color: black;
                }

            }

            &-text {
                &-container {
                    width: 100%;
                }

                font-size:14px;
                padding:10px;

                width: 100%;

                a {
                    color: blue;
                }
            }

            &-create {
                font-size:12px;
                display: flex;
                justify-content: left;
                align-items: center;
                color: grey;
                padding:10px 10px 0 10px;
            }

            &-footer {
                display: flex;
                justify-content: space-between;
                padding:0;
            }


            &-date {
                font-size:12px;
                display: flex;
                justify-content: center;
                align-items: center;
                color: grey;
                padding:10px;

            }


            &-update {
                font-size:14px;
                padding:0;
            }
        }

    }

    &__newnote {

        display: flex;
        justify-content:space-between;

        &-search {
            flex-basis: 50%;

            input[type=text] {
                width:100%;
                margin: 20px 10px 5px 10px;
                background-color: transparent;
                border: 0;
                font-size:14px;
                border-bottom:1px solid silver;
                
            }
        }


        &-but {
            font-size:14px;
            border:1px solid silver;
            padding: 10px;
            border-radius: 5px;
            margin: 20px 10px 5px 10px;
        }


        i  {
            font-size:18px;
        }

        a {
            text-decoration: none;
            color:black;
        }
    }
 }

 </style>